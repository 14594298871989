import React, { useEffect, useState, useRef, useContext } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import Popup from "reactjs-popup";
import { format } from "date-fns";

import NavLinks from "../../utils/navLinks";
import { DropdownNormal } from "../common/DropDownNormal";
import { FieldWrapper } from "../common/FieldWrapper";
import { TextField } from "../common/TextFeild";
import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import greenIMG from "../../assets/images/green-dot.png";
import A from "../../utils/urls.utils";
import {
  CONST_USER,
  CONST_INSTANT,
  CONST_DAILYTRIP,
  CONST_USERCANCELLED,
  CONST_MILES_VALUE,
  CONST_EXPIRED,
} from "../../utils/constantUtils";
import { INSTANT } from "../../utils/utils";
import useAdmin from "../hooks/useAdmin";
import Review from "./Review";
import { PopUp } from "../common/PopUp";
import { Modal } from "../common/Model";
import Login from "./Login";
import BookingConform from "./BookingConfirmed";
import {
  FiThumbsDown,
  FiKey,
  FiPhone,
  FiStar,
  FiPercent,
  FiLifeBuoy,
  FiCo,
} from "react-icons/fi";
import { MdDiscount, MdOutlineLabelImportant } from "react-icons/md";
import { VscChevronDown } from "react-icons/vsc";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modals from "react-bootstrap/Modal";
import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import { RideDeatilscontext } from "../context/RideDeatils";
import { CardDeatilscontext } from "../context/CardDeatilsContext";

import socketIO from "socket.io-client";
import Cookies from "js-cookie";
// import { useParams } from "react-router";
import Notification from "./Notification/index";

import useSocket from "../hooks/useSocket";
import NavBar from "../navBar/NavBar";
import { BiChevronDown } from "react-icons/bi";
import { SmallLoader } from "../common/SmallLoader";

export const rideDeatils = atom({
  key: "rideDeatils",
  default: null,
});

export default function RideBooking({
  rideBooking,
  pickUp,
  drop,
  estmationTravelDistance = 0,
  estmationTravelTime = 0,
  // categoriesData,
  booking,
  categories,
  bookingType,
  couponAmount = 0,
  currentLocation,
}) {
  const mapArea = React.useRef();

  const PAYBY = ["CASH", "WALLET", "CARD"];
  //   {
  //     id: 1,
  //     label: "Cash",
  //     value: "CASH",
  //   },
  //   {
  //     id: 2,
  //     label: "Walet",
  //     value: "WALLET",
  //   },
  //   {
  //     id: 2,
  //     label: "Card",
  //     value: "CARD",
  //   },
  // ];
  const { rideDeatilsData, setRideDeatilsData } =
    useContext(RideDeatilscontext);

  const { CardDeatilsData, setCardDeatilsData } =
    useContext(CardDeatilscontext);

  const { notificationSocket } = useSocket();

  const { settings } = useSettings();
  const { language } = useLanguage();
  const { header, userProfileFetch } = useAdmin();
  const navigate = useNavigate();
  const retryInterval = useRef();
  const progressBarInterval = useRef();
  // const { ID } = useParams();

  let intervalId;
  //   const { state } = useLocation();
  const [paymentType, setPaymentType] = useState("CASH");
  const [map, setMap] = useState(null);
  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [bookingId, setBookingId] = useState(null);
  const [rideStatus, setRideStatus] = useState("");
  //   const [rideAcepted, setRideAcepted] = useState(false);
  const [rideDetails, setRideDetails] = useState(null);
  const [popup, setPop] = React.useState(null);
  const [popUpLogin, setPopUpLogin] = useState(false);
  const [userValue, setUserValue] = useState();
  const [directionData, setDirectionData] = useState(null);
  const [direction, setDirection] = useState(null);
  const [searchingProfessional, setSearchingProfessional] = useState(false);
  const [coupon, setCoupon] = useState();
  const [couponId, setCouponId] = useState(null);
  const [fareAmount, setFareAmount] = useState(null);
  const [isEnableCoupon, setIsEnableCoupon] = useState(0);
  const [user, setUser] = useState();
  const [retryCount, setRetryCount] = useState(0);
  const [bookingAccepted, setBookingAccepted] = React.useState(false);
  const [type, setType] = React.useState("TIMER");
  const [popUpContent, setPopUpContent] = useState(true);
  const [show, setShow] = useState(false);
  // const [cardDeatils, setCardDeatils] = useState();
  const [paymentIntern, setPaymentInten] = useState();
  const [distance, setDistance] = useState(settings?.requestDistance);

  const [rideDeatil, setRideDeatil] = useRecoilState(rideDeatils);

  const [socketData, setSocketData] = useState(null);
  const [showNotification, setNotification] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const [notificationData, setNotificationData] = React.useState([]);
  const [onlyMeNotification, setOnlyMeNotification] = React.useState([]);
  const [paymenTypeCoupon, setPaymenTypeCoupon] = React.useState();
  const [couponLatlng, setCouponLatlng] = React.useState({});

  useEffect(() => {
    // alert(JSON.stringify(rideDeatilsData?.categories?.paymentMode));
    // console.log(rideDeatilsData);
    // console.log(rideDeatilsData);
  }, []);

  // .............................

  // let accesToken = Cookies.get("CBU").substring(13);
  // const socketUrl =
  // "https://socket.cabzone.co.uk/webBooking?transports=websocket";
  // const socketUrl = "https://socket.cabzone.co.uk/notification";
  // let socket = socketIO(socketUrl, {
  //   forceNew: true,
  //   path: "/socket.io",
  //   reconnection: true,
  //   reconnectionDelay: 2000, //starts with 2 secs delay, then 4, 6, 8, until 60 where it stays forever until it reconnects
  //   reconnectionDelayMax: 60000, //1 minute maximum delay between connections
  //   reconnectionAttempts: "Infinity", //to prevent dead clients, having the user to having to manually reconnect after a server restart.
  //   timeout: 10000,
  //   // transports: [settings?.isSocketPolling === false ? "websocket" : "polling"],
  //   transports: ["websocket"],
  //   query: {
  //     accessToken: `${accesToken}`,
  //     deviceId: accesToken,
  //   },
  // });
  // // let socket = socketIO.connect(socketUrl);
  // // useEffect(() => {
  // //   socket.on("connect", (each) => {
  // //     // console.log(socket.id);
  // //     console.log("---------------");
  // //     // console.log(each);
  // //   });
  // //   // return () => {
  // //   //   // User leaves room
  // //   //   socket.disconnect();
  // //   //   socket.off();
  // //   // };
  // // }, []);
  // ........................................................................

  // useEffect(() => {
  //   // const intervelValue = setInterval(() => {
  //   //   // alert("_____1");
  //   //   console.log(socket);

  //   //   socket.emit("trackBookingDetailsByIdFromWebBookingApp", {
  //   //     bookingId: "6322f607d00f6024d1d7b3af",
  //   //   });
  //   //   socket.on("trackBookingDetailsByIdFromWebBookingApp", (each) => {
  //   //     console.log("each");
  //   //     alert("_____2");

  //   //     console.log(each);
  //   //     alert(each);
  //   //   });
  //   //   // alert("_____3");
  //   // }, 10000);
  //   console.log(`⚡: ${socket.id} user just connected!`);
  //   socket.on("trackUserLocation", (data) => {
  //     socketIO.emit(
  //       "trackUserLocation",
  //       {
  //         userId: "6322afaa656dd51cce3a54d9",
  //       },
  //       data
  //     );
  //     setSocketData(data);
  //     console.log(data);
  //   });
  //   //Listens when a new user joins the server

  //   // socket.on("disconnect", () => {
  //   //   console.log("🔥: A user disconnected");
  //   //   //Updates the list of users when a user disconnects from the server
  //   //   // console.log(users);
  //   //   //Sends the list of users to the client
  //   //   socket.disconnect();
  //   // });
  //   // socketIO.on("connection", (socket) => {
  //   //   console.log(`⚡: ${socket.id} user just connected!`);
  //   //   socket.on("trackUserLocation", (data) => {
  //   //     socketIO.emit(
  //   //       "trackUserLocation",
  //   //       {
  //   //         userId: "6322afaa656dd51cce3a54d9",
  //   //       },
  //   //       data
  //   //     );
  //   //   });
  //   //   //Listens when a new user joins the server

  //   //   socket.on("disconnect", () => {
  //   //     console.log("🔥: A user disconnected");
  //   //     //Updates the list of users when a user disconnects from the server
  //   //     // console.log(users);
  //   //     //Sends the list of users to the client
  //   //     socket.disconnect();
  //   //   });
  //   // });
  //   console.log(socket);
  // }, [socket]);
  // ........................................................................
  // const intervelValue = setInterval(
  //   socket.on("trackBookingDetailsByIdFromWebBookingApp", (each) => {
  //     console.log("each");
  //     console.log(JSON.stringify(each));
  //   }),
  //   10000
  // );

  // useEffect(() => {
  //   console.log(socketData);
  // }, [socketData]);

  // const fetchData = async () => {
  //   try {
  //     const { data } = await axios.post(
  //       A.HOST + A.ADMIN_DASHBOARD_NOTIFICATION,
  //       {},
  //       header
  //     );
  //     setNotificationData([
  //       ...[
  //         ...data.reportsData,
  //         ...data.securityData,
  //         ...data.scheduleData,
  //         ...data.professionalCancelData,
  //         ...onlyMeNotification,
  //       ]
  //         .sort(function (a, b) {
  //           return new Date(b.createdAt) - new Date(a.createdAt);
  //         })
  //         // .map((each) => console.log({ id: each._id, time: format(new Date(each.createdAt), "p") }));
  //         .map((each) => {
  //           if (each.userType) {
  //             return {
  //               id: each._id,
  //               category: "REPORT",
  //               subCategory: null,
  //               firstName: each.firstName,
  //               lastName: each.lastName,
  //               phoneCode: each.phone?.code,
  //               phoneNumber: each.phone?.number,
  //               comment: each.comment,
  //               time: format(new Date(each.createdAt), "do LLL  p"),
  //             };
  //           } else if (each.bookingFor) {
  //             return {
  //               id: each._id,
  //               name:
  //                 each.bookingFor.name === "GUEST"
  //                   ? "GUEST"
  //                   : each.bookingFor.name,
  //               phoneCode: each.bookingFor?.phoneCode,
  //               phoneNumber: each.bookingFor?.phoneNumber,
  //               bookingStatus: each.bookingStatus,
  //               category: "RIDE",
  //               time: format(new Date(each.bookingDate), "do LLL  p"),
  //             };
  //           } else if (each.category === "SECURITY_END") {
  //             return {
  //               id: each._id,
  //               category: "SECURITY_END",
  //               firstName: each.firstName,
  //               lastName: each.lastName,
  //               phoneCode: each.phoneCode,
  //               phoneNumber: each.phoneNumber,
  //               time: format(new Date(each.createdAt), "do LLL  p"),
  //             };
  //           } else {
  //             return {
  //               id: each._id,
  //               category: "SECURITY",
  //               subCategory: each.alertType,
  //               firstName: each.firstName,
  //               lastName: each.lastName,
  //               phoneCode: each.phone?.code,
  //               phoneNumber: each.phone?.number,
  //               time: format(new Date(each.createdAt), "do LLL  p"),
  //             };
  //           }
  //         }),
  //     ]);
  //     setShowAlert(
  //       firstLoad === true &&
  //         (data.reportsData.length !== 0 || data.securityData.length !== 0)
  //         ? true
  //         : false
  //     );
  //     setFirstLoad(false);
  //   } catch (err) {
  //     //   authFailure(err);
  //     alert(err);
  //   }
  // };

  useEffect(() => {
    window.addEventListener(
      "popstate",
      async (event) => {
        setTimeout(window.history.forward(), 0);
        // console.log(event);
      },
      false
    );
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  useEffect(() => {
    setRideDeatilsData(JSON.parse(localStorage.getItem("SelectedOption")) || 1);
    setCouponLatlng(JSON.parse(localStorage.getItem("CurrentLocation")));
    console.log(JSON.parse(localStorage.getItem("SelectedOption")));
    // console.log("selectedOption");
    // console.log(rideDeatilsData);
    // setCardDeatils(JSON.parse(localStorage.getItem("CardDeatils")) || 1);
    // CardDeatilsData
    //   ? alert(JSON.stringify(CardDeatilsData))
    //   : alert(JSON.stringify("cardDeatils ___ No"));
    // alert(JSON.parse(localStorage.getItem("CurrentLocation")));

    if (CardDeatilsData?._id) {
      setPaymentType("CARD");
      // alert(JSON.stringify(CardDeatilsData));
    }

    // alert(JSON.stringify(cardDeatils));
    // if (paymentType !== "CARD") {
    //   localStorage.setItem("CardDeatils", null);
    // }
    // console.log(settings);
  }, []);

  useEffect(() => {
    // console.log(rideDeatilsData?.categories?.categories);
    // gsap.fromTo(
    //   ".dataTable",
    //   0.5,
    //   { opacity: 0 },
    //   { opacity: 1, stagger: 0.1 }
    // );
    //   }, []);
    //   useEffect(() => {
    //     // alert(JSON.stringify(rideBooking));
    //     console.log("rideBooking" + console.log(rideBooking));
    // console.log(user);
    // console.log("user" + user);
    //     console.log("booking" + console.log(booking));
    //     console.log(bookingType);
    //     //     console.log(drop.results[0].geometry.location);
    // console.log("UserData");
    // console.log(booking);
    setTimeout(() => {
      if (header) {
        userProfile();
        // getCouponList();
      }
    }, 50);
    // setTimeout(() => {
    //   if (header) {
    //     getCouponList();
    //   }
    // }, 200);
  }, []);

  const userProfile = async () => {
    try {
      // console.log(header);
      // alert("header__ headerProfile" + header);

      const { data } = await axios.post(
        A.HOST + A.USER_PROFILE_GET,
        {},
        header
      );
      // alert(JSON.stringify(data));
      // getCouponList();
      // alert("header__" + header);
      if (data.code === 200) {
        setUser(data.data);
      }
      // console.log(data.data);
    } catch (err) {
      if (err.response.data.code === 401) {
        // window.location.reload();
        // userProfile();
      }
      //   authFailure(err);
      // alert(err);
    }
  };

  useEffect(() => {
    // console.log(currentLocation);
  }, [currentLocation]);

  // const userProfileFile = async () => {
  //   const UserData = await userProfileFetch();
  //   setUser(UserData);
  //   console.log(UserData);
  // };
  //   try {
  //     const { data } = await axios.post(
  //       A.HOST + A.USER_PROFILE_GET,
  //       {},
  //       header
  //     );
  //     // alert(JSON.stringify(data));
  //     setUser(data.data);
  //   } catch (err) {
  //     //   authFailure(err);
  //     alert(err);
  //   }
  // // };

  React.useEffect(() => {
    if (googleLoaded) {
      const googleMap = new window.google.maps.Map(mapArea.current, {
        // center: U.mode === "pamworld" ? { lat: 9.476411, lng: 6.600669 } : { lat: 8.9956441, lng: 38.7816384 },
        center: {
          lat:
            rideDeatilsData.pickUp?.results[0]?.geometry.location?.lat ||
            28.6134594,
          lng:
            rideDeatilsData.pickUp?.results[0]?.geometry.location?.lng ||
            77.21191,
          //   lat: 28.6134594,
          //   lng: 77.21191,
        },
        zoom: 14,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
      });
      setMap(googleMap);

      // const pickUpMarker = new window.google.maps.Marker({
      //   position: {
      //     lat: settings?.location?.lat,
      //     lng: settings?.location?.lng,
      //   },
      //   map: map,
      // });
    }
  }, [googleLoaded]);

  useEffect(() => {
    if (settings?.mapApi?.web) {
      const loader = new Loader({
        apiKey: settings?.mapApi?.web,
        version: "weekly",
        libraries: ["places"],
      });
      loader
        .load()
        .then(() => {
          setGoogleLoaded(true);
        })
        .catch((e) => {});
    }
  }, [settings?.mapApi?.web]);

  //   React.useEffect(() => {
  //     // alert(driverMarkers);
  //     // driverMarkers.length > 0 && driverMarkers.forEach((each) => each.setMap(null));
  //     // setNoDrivers(false);
  //     // setPickUpOutside(false);
  //     // setDropOutside(false);
  //     // checkAvailableDrivers();
  //     // if (markers.pickUp !== null) markers.pickUp.setMap(null);
  //     new window.google.maps.Marker({
  //       icon: {
  //         url: greenIMG,
  //         scaledSize: new window.google.maps.Size(35, 35),
  //       },
  //       position: {
  //         lat: pickUp.results[0].geometry.location.lat,
  //         lng: pickUp.results[0].geometry.location.lng,
  //       },
  //       map: map,
  //     });
  //     // map.setCenter({
  //     //   lat: pickUp.results[0].geometry.location.lat,
  //     //   lng: pickUp.results[0].geometry.location.lng,
  //     // });
  //     // setMarkers({ ...markers, pickUp: pickUpMarker });
  //     // setCategories(null);
  //   }, [map]);

  //   useEffect(() => {
  //     new window.google.maps.Marker({
  //       icon: {
  //         url: greenIMG,
  //         scaledSize: new window.google.maps.Size(35, 35),
  //       },
  //       position: {
  //         //   lat: settings?.location?.lat,
  //         //   lng: settings?.location?.lng,
  //         lat: drop.results[0].geometry.location?.lat,
  //         lng: drop.results[0].geometry.location?.lng,
  //       },
  //       map: map,
  //     });
  //   }, [map]);

  React.useEffect(() => {
    const check = async () => {
      if (rideDeatilsData?.pickUp !== null && rideDeatilsData?.drop !== null) {
        // if (markers.pickUp !== null) markers.pickUp.setMap(null);
        // if (markers.drop !== null) markers.drop.setMap(null);
        // if (direction !== null) direction.setMap(null);

        // alert(rideDeatilsData?.pickUp.results[0].geometry.location.lat);
        //MARKER
        const coordinatesForPath = [
          {
            lat: rideDeatilsData?.pickUp?.results[0].geometry.location.lat,
            lng: rideDeatilsData?.pickUp?.results[0].geometry.location.lng,
          },
          {
            lat: rideDeatilsData?.drop?.results[0].geometry.location.lat,
            lng: rideDeatilsData?.drop?.results[0].geometry.location.lng,
          },
        ];
        var bounds = new window.google.maps.LatLngBounds();
        coordinatesForPath.forEach((poly) => bounds.extend(poly));
        map?.fitBounds(bounds);

        //CREATING DIRECTIONS
        const directionsService = new window.google.maps.DirectionsService();
        const directionsRenderer = new window.google.maps.DirectionsRenderer();
        var request = {
          origin: {
            lat: rideDeatilsData?.pickUp?.results[0].geometry.location.lat,
            lng: rideDeatilsData?.pickUp?.results[0].geometry.location.lng,
          },
          destination: {
            lat: rideDeatilsData?.drop?.results[0].geometry.location.lat,
            lng: rideDeatilsData?.drop?.results[0].geometry.location.lng,
          },
          travelMode: "DRIVING",
        };
        // if (stops.filter((stop) => stop.updated === true).length > 0) {
        //   request["waypoints"] = stops
        //     .filter((stop) => stop.updated === true)
        //     .map((stop) => ({
        //       location: new window.google.maps.LatLng(stop.lat, stop.lng),
        //       stopover: true,
        //     }));
        // }
        directionsService.route(request, function (result, status) {
          if (status === "OK") {
            directionsRenderer.setDirections(result);
            setDirectionData(result);
            // console.log("result");
            // console.log(result);
          }
        });

        directionsRenderer.setMap(map);
        setDirection(directionsRenderer);

        //#region Intercity Ride
        // if (categories?.serviceCategory === CONST_INTERCITYRIDE.toLowerCase()) {
        //   const pickupDirectionsService = new window.google.maps.DirectionsService();
        //   var pickupRequest = {
        //     origin: {
        //       lat: settings?.location?.lat,
        //       lng: settings?.location?.lng,
        //     },
        //     destination: {
        //       lat: pickUp.results[0].geometry.location.lat,
        //       lng: pickUp.results[0].geometry.location.lng,
        //     },
        //     travelMode: "DRIVING",
        //   };
        //   pickupDirectionsService.route(pickupRequest, function (result, status) {
        //     if (status === "OK") {
        //       setpickupDirectionData(result);
        //     }
        //   });
        // }
        //#endregion Intercity Ride
      }
    };
    check();
    // setCategories(null);
  }, [map]);

  //   const checkAvailableDrivers = async () => {
  //     // alert("admin" + JSON.stringify(userProfile));
  //     // alert("Professional_" + JSON.stringify(header));
  //     // setDriverLoading(true);
  //     // setBookingDetails({ ...bookingDetails, category: null });
  //     try {
  //       // driverMarkers.length > 0 &&
  //       //   driverMarkers.map((each) => each.setMap(null));
  //       // setSelectableCategory([]);
  //       const { data } = await axios.post(
  //         A.HOST + A.USER_BOOK_DRIVERS_LIST,
  //         {
  //           lat:
  //             address === true
  //               ? currentLocation.lat
  //               : pickUp.results[0].geometry.location.lat,
  //           lng:
  //             address === true
  //               ? currentLocation.lng
  //               : pickUp.results[0].geometry.location.lng,
  //           radius: selectedDistance * CONST_MILES_VALUE,
  //           serviceCategory: categories?.serviceCategory,
  //           serviceAreaId: categories?.serviceAreaId,
  //         }
  //         // header
  //       );
  //       // alert("Professional_List" + JSON.stringify(data));
  //       // setDriverMarkers(
  //       //   data.map(
  //       //     (driver) =>
  //       //       new window.google.maps.Marker({
  //       //         icon: {
  //       //           url: carIcon,
  //       //           scaledSize: new window.google.maps.Size(35, 35),
  //       //         },
  //       //         position: {
  //       //           lat: driver.location.coordinates[1],
  //       //           lng: driver.location.coordinates[0],
  //       //         },
  //       //         map: map,
  //       //       })
  //       //   )
  //       // );

  //       setDrivers(data);
  //       let driverAcceptableCategories = [];
  //       data.forEach((driver) => {
  //         driver.vehicles.forEach((vehicle) => {
  //           if (vehicle.defaultVehicle === true) {
  //             driverAcceptableCategories.push(vehicle.vehicleCategoryId);
  //             if (
  //               vehicle.isSubCategoryAvailable === true &&
  //               vehicle.applySubCategory === true
  //             ) {
  //               vehicle.subCategoryIds.map((each) =>
  //                 driverAcceptableCategories.push(each)
  //               );
  //             }
  //           }
  //         });
  //       });
  //       driverAcceptableCategories = [...new Set(driverAcceptableCategories)];

  //       setSelectableCategory(driverAcceptableCategories);
  //       setPickUpOutside(false);
  //       setDropOutside(false);
  //       setDriverLoading(false);
  //       if (driverAcceptableCategories.length > 0) setNoDrivers(false);
  //       else setNoDrivers(true);
  //       return true;
  //     } catch (err) {
  //       // authFailure(err);
  //       alert("setNoDrivers" + err);
  //       // setPop({ title: language.NUMBER_IN_ANOTHER_DIAL_CODE, type: "error" });
  //       setNoDrivers(true);
  //       setDriverLoading(false);
  //       return false;
  //     }
  //   };

  const bookRide = async () => {
    // alert("header" + " _________ " + JSON.stringify(header));

    // let apiLink =
    //   bookingDetails.bookingType === U.INSTANT
    //     ? A.ADMIN_BOOK_RIDE_INSTANT
    //     : A.ADMIN_BOOK_RIDE_SCHEDULE;
    // alert(JSON.stringify(CardDeatilsData));
    const bookingLocations = [];
    bookingLocations.push({
      addressName:
        rideDeatilsData.pickUp.selectedAddress.formatted_address ||
        rideDeatilsData.pickUp?.selectedAddress,
      fullAddress:
        rideDeatilsData.pickUp.selectedAddress.formatted_address ||
        rideDeatilsData.pickUp?.selectedAddress,
      shortAddress:
        rideDeatilsData.pickUp.selectedAddress.formatted_address ||
        rideDeatilsData.pickUp?.selectedAddress,
      lat: rideDeatilsData.pickUp.results[0].geometry.location.lat,
      lng: rideDeatilsData.pickUp.results[0].geometry.location.lng,
      type: "ORIGIN",
    });

    // if (stops.filter((stop) => stop.updated === true).length > 0)
    // stops
    //   .filter((stop) => stop.updated === true)
    //   .map((stop) =>
    //     bookingLocations.push({
    //       addressName: stop.address,
    //       fullAddress: stop.address,
    //       shortAddress: stop.address,
    //       lat: stop.lat,
    //       lng: stop.lng,
    //       type: "STOP",
    //     })
    //   );
    bookingLocations.push({
      addressName: rideDeatilsData?.drop.selectedAddress,
      fullAddress: rideDeatilsData?.drop.selectedAddress,
      shortAddress: rideDeatilsData?.drop.selectedAddress,
      // lat: drop.results[0].geometry.location.lat,
      // lng: drop.results[0].geometry.location.lng,
      lat: rideDeatilsData?.drop.results[0].geometry.location.lat,
      lng: rideDeatilsData?.drop.results[0].geometry.location.lng,
      type: "DESTINATION",
    });
    if (paymentType === "CARD") {
      // alert(paymentType);
      if (user.paymentGateWay.isPreAuthRequired === true) {
        try {
          const { data } = await axios.post(
            A.HOST + A.CARD_PREAUTH_PAYMENT,
            {
              paymentGatway: user.paymentGateWay.gateWay,
              userType: CONST_USER,
              userId: user.userId,
              cardId: CardDeatilsData?._id,
              estimationAmount:
                fareAmount ||
                rideDeatilsData?.rideBooking.estimationPayableAmount,
              // cvv: cardDeatils.cardNumber,
            },
            header
          );
          setPaymentInten(data);
          // console.log("preAuth");
          // console.log(data);
          setPopUpContent(data.message);
        } catch (err) {
          alert(err);
        }
      }
    }

    // alert("paymentType ---- No");

    // return;
    try {
      // alert("datajbhnu inhu");
      if (!header) {
        navigate(NavLinks.LOG_IN + "rideDeatilsData");
        // setPopUpLogin(true);
        // alert("fffffffff");
        //   header
      }
      let appdata = {
        bookingBy: CONST_USER,
        langCode: user.languageCode || "en",
        vehicleCategoryId: rideDeatilsData?.rideBooking.categoryID,
        categoryId: rideDeatilsData?.categories.serviceAreaID,
        type: CONST_INSTANT,
        bookingType: rideDeatilsData?.bookingType, //CONST_INSTANT,
        pickUpAddressName:
          rideDeatilsData?.pickUp.selectedAddress.formatted_address ||
          rideDeatilsData?.pickUp.selectedAddress,
        pickUpFullAddress:
          rideDeatilsData?.pickUp.selectedAddress.formatted_address ||
          rideDeatilsData?.pickUp.selectedAddress,
        // shortAddress:
        //   rideDeatilsData?.pickUp.selectedAddress.formatted_address ||
        //   rideDeatilsData?.pickUp.selectedAddress,
        pickUpShortAddress:
          rideDeatilsData?.pickUp.selectedAddress.formatted_address ||
          rideDeatilsData?.pickUp.selectedAddress,
        pickUpLat: rideDeatilsData?.pickUp.results[0].geometry.location.lat,
        pickUpLng: rideDeatilsData?.pickUp.results[0].geometry.location.lng,
        // pickUpLat: 8.3022,
        // pickUpLng: 77.2231,
        dropAddressName: rideDeatilsData?.drop.selectedAddress,
        dropFullAddress: rideDeatilsData?.drop.selectedAddress,
        dropShortAddress: rideDeatilsData?.drop.selectedAddress,
        dropLat: rideDeatilsData?.drop.results[0].geometry.location.lat,
        dropLng: rideDeatilsData?.drop.results[0].geometry.location.lng,
        isGenderAvailable: false,
        childseatAvailable: false,
        handicapAvailable: false,
        tripType: CONST_DAILYTRIP,
        serviceAreaId: rideDeatilsData?.categories.serviceAreaID,
        paymentOption: paymentType,
        estimationAmount:
          paymentType === "WALLET"
            ? user.wallet.availableAmount <= fareAmount ||
              rideDeatilsData?.rideBooking.estimationPayableAmount
              ? fareAmount ||
                rideDeatilsData?.rideBooking.estimationPayableAmount
              : null
            : fareAmount ||
              rideDeatilsData?.rideBooking.estimationPayableAmount,
        //coupon Applied Amount
        // estimationAmount: 200, //coupon Applied Amount
        actualEstimationAmount:
          rideDeatilsData?.rideBooking.estimationPayableAmount,
        serviceTax: rideDeatilsData?.rideBooking.serviceTax,
        travelCharge: rideDeatilsData?.rideBooking.travelCharge,
        distanceFare: rideDeatilsData?.rideBooking.distanceFare,
        timeFare: rideDeatilsData?.rideBooking.timeFare,
        siteCommisionValue: rideDeatilsData?.rideBooking.siteCommisionValue,

        card: { cardId: paymentType === "CARD" ? CardDeatilsData?._id : null },
        paymentIntentId: "0",
        paymentIntentAmount: 0,
        bookingDate: rideDeatilsData?.booking.date,
        bookingFor: {
          name: user?.firstName || "GUEST",
          phoneCode: user?.phone.code, //"+91",
          phoneNumber: user?.phone.number, //"9600449653",
          // name: "GUEST",
          // phoneCode: "+91",
          // phoneNumber: "9600449653",
        },
        bookingFrom: "WEBAPP",
        // couponId: "",
        // couponAmount: 0,
        // couponValue: 0,
        // couponType: "",
        // couponApplied: false,
        // isProfessionalTolerance: false,

        couponId: couponId?._id || "",
        couponAmount: couponAmount || 0,
        couponValue: couponId?.couponAmount || 0,
        couponType: couponId?.couponType || "",
        couponApplied: couponId ? true : false,
        isProfessionalTolerance: couponId?.isProfessionalTolerance || false,
        estimationDistance: rideDeatilsData?.estmationTravelDistance,
        estimationTime: rideDeatilsData?.estmationTravelTime,
        estimationPickupDistance:
          rideDeatilsData?.rideBooking.estimationPickupDistance,
        estimationPickupTime: rideDeatilsData?.rideBooking.estimationPickupTime,
        estimationDropDistance:
          rideDeatilsData?.categories.estimationDropDistance, //
        estimationDropTime: rideDeatilsData?.categories.estimationDropTime,
        serviceCategory: rideDeatilsData?.categories.serviceCategory,
        bookingLocations: bookingLocations,
        // rentalPackage: "",
        intercityPickupTimeFare:
          rideDeatilsData?.rideBooking.intercityPickupTimeFare,
        intercityPickupDistanceFare:
          rideDeatilsData?.rideBooking.intercityPickupDistanceFare,
        intercityPickupCharge:
          rideDeatilsData?.rideBooking.intercityPickupCharge,

        intercityPickupFarePerDistance:
          rideDeatilsData?.rideBooking.intercityPickupFarePerDistance,
        intercityPickupFarePerMinites:
          rideDeatilsData?.rideBooking.intercityPickupFarePerMinites,
        intercityBoundaryFare:
          rideDeatilsData?.rideBooking.intercityBoundaryFare,

        radius: distance, //settings.requestDistance, //retryRequestDistance

        latLng: {
          lat:
            rideDeatilsData?.currentLocation?.lat ||
            rideDeatilsData?.pickUp?.results[0].geometry.location.lat,
          lng:
            rideDeatilsData?.currentLocation?.lng ||
            rideDeatilsData?.pickUp?.results[0].geometry.location.lng,
          // lat: 8.3022,
          // lng: 77.2231,
          timeStamp: 34324, //user.timeStamp
        },
        roundingType: rideDeatilsData?.categories.roundingType,
        roundingFactor: rideDeatilsData?.categories.roundingFactor,
        isNotify: true,
      };

      //   else if (header) {
      //     navigate(NavLinks.RIDE_BOOKING);
      //   }
      // return;
      setRideDeatil(appdata);
      // console.log(appdata);
      // alert("data____1");
      const { data } = await axios.post(
        A.HOST + A.USER_RIDE_BOOKING,
        appdata,
        header
      );
      // alert("data____1" + data);
      // alert(data.data._id);
      // console.log(data);
      if (data.code === 200) {
        setBookingId(data.data._id);
        setRideDetails(data.data);
        // console.log(data.data);
        setCardDeatilsData({});
      } else if (data.code === 503) {
        // alert(data.message);
        setShow(true);

        setPopUpContent(language.NO_PROFESSIONALS_AVAILABLE);
        // setPop({ title: "professional Not Found", type: "error" });
        // setPop({ title: language.NO_PROFESSIONALS_AVAILABLE, type: "error" });
        // setPop({ title: parseError(err), type: "error" });
      } else if (data.code === 500) {
        setShow(true);
        setPopUpContent(data.message);
        //   // alert(data.message);
      }
      // setPopUpContent(data.message);
      setDistance(distance + settings.retryRequestDistance);
      // alert(settings.bookingAlgorithm);
      // if (data.data._id && rideDeatilsData.bookingType === CONST_INSTANT) {
      //   navigate(NavLinks.SEARCHING_PROFESSIONALS + "/" + data.data._id);
      // } else {
      //   navigate(NavLinks.SCHEDULE_RIDE_BOOKING + "/" + data.data._id);
      // }
      // fetchBookingDeatils(data.data._id);
    } catch (err) {
      setRideDetails(null);
      // alert(err);
      if (paymentType === "WALLET") {
        setShow(true);
        setPopUpContent(
          "KINDLY RECHARGE YOUR WALLET OR CHOOSE ANY OTHER PAYMENT OPTION"
        );
      }
      // setPopUpContent(language.ERROR_ALL_FIELD_REQUIRED);
      // alert(err);
    }
  };

  //   useEffect(() => {
  //   const professionalAcceptBooking = async () => {
  //     try {
  //   alert("header" + " _________ " + JSON.stringify(header));

  // function onBlur() {
  //   // document.body.className = 'blurred';
  //   // console.log("blurred_" + new Date().getTime());
  //   // setTime(8);
  //   // setLive(0);
  // }
  // function onFocus() {
  //   // document.body.className = 'focused';
  //   // console.log("focused_" + new Date().getTime());
  //   // setLive(1);
  //   // setTimeout(() => {
  //   // fetchBookingDeatils();
  //   // }, 5000);
  // }
  // if (/*@cc_on!@*/ false) {
  //   // check for Internet Explorer
  //   document.onfocusin = onFocus;
  //   document.onfocusout = onBlur;
  // } else {
  //   window.onfocus = onFocus;
  //   window.onblur = onBlur;
  // }
  // alert(JSON.stringify(data));
  // ........................
  // useEffect(() => {
  //   console.log("notificationSocket");
  //   console.log(notificationSocket);
  //   if (notificationSocket.connected) {
  //     notificationSocket.current.on(
  //       "trackBookingStatusForWebBookingApp",
  //       (data) => {
  //         alert(JSON.stringify(data));
  //       }
  //     );
  //   }
  // }, [notificationSocket]);
  // notificationSocket.current.on(
  //   "trackBookingStatusForWebBookingApp",
  //   (data) => {
  //     alert(JSON.stringify(data));
  //   }
  // );

  // ...............

  // function NavigateFun(data) {
  //   // alert();
  //   // data.userAccessToken + "---------- " + Cookies.get("CBU").substring(13)
  //   // console.log(data.userAccessToken);
  //   if (data.userAccessToken === Cookies.get("CBU").substring(13)) {
  //     // if (data.bookingId) {
  //     // if (data.bookingId === data.bookingId) {
  //     // alert(data.userAccessToken + " " + data.bookingId);
  //     if (data.bookingStatus === "AWAITING") {
  //       // alert("retry");
  //       //   retry();
  //       //   clearInterval(intervalId);
  //     } else if (
  //       data.bookingStatus === "ACCEPTED" ||
  //       data.bookingStatus === "ARRIVED"
  //     ) {
  //       //   alert(data.data.bS);
  //       //   navigate(
  //       //     NavLinks.RIDE_CONFIRMED +
  //       //       "/" +
  //       //   pickUp.selectedAddress.formatted_address ||
  //       //     pickUp.selectedAddress + "/" + drop.selectedAddress;
  //       //   );
  //       //   setSearchingProfessional(false);

  //       //   setRideStatus(data.data.bS);

  //       navigate(NavLinks.RIDE_CONFIRMED + "/" + data.bookingId);
  //       //   setRideAcepted(true);
  //     } else if (data.bookingStatus === "STARTED") {
  //       //   setSearchingProfessional(false);

  //       navigate(NavLinks.START_RIDE + "/" + data.bookingId);
  //     } else if (data.bookingStatus === "ENDED") {
  //       //   setSearchingProfessional(false);

  //       //   clearInterval(intervalId);
  //       //   setRideStatus(data.data.bS);

  //       //   setRideAcepted(false);
  //       //   setRideStatus(data.data.bS === "ENDED" ? true : false);

  //       navigate(NavLinks.RIDE_REVIEW + "/" + data.bookingId);
  //     } else if (
  //       data.bookingStatus === "USERCANCELLED" ||
  //       data.bookingStatus === "USERDENY"
  //     ) {
  //       //   setSearchingProfessional(false);

  //       //   clearInterval(intervalId);
  //       navigate(NavLinks.RIDE_BOOKING);
  //     } else if (data.bookingStatus === "EXPIRED") {
  //       setPop({ title: "No Professional Found ", type: "error" });
  //       setShow(true);
  //       setPopUpContent(language.NO_PROFESSIONALS_AVAILABLE);
  //     }
  //   }
  // }

  // React.useEffect(
  //   () => console.log(notificationData),
  //   [notificationData, bookingId]
  // );

  // React.useEffect(() => {
  //   // setOnlyMeNotification([
  //   //   ...onlyMeNotification,
  //   //   {
  //   //     _id: "",
  //   //     firstName: "Benzigar",
  //   //     lastName: "Codes",
  //   //     phoneCode: "+91",
  //   //     phoneNumber: "9791442121",
  //   //     category: "SECURITY_END",
  //   //     createdAt: new Date(),
  //   //   },
  //   // ]);
  //   // const token = Cookies.get("CBU").substring(13);
  //   notificationSocket.current.on(
  //     "trackBookingStatusForWebBookingApp",
  //     (data) => {
  //       // alert("mynotify_______2" + JSON.stringify(data));
  //       // alert(JSON.stringify(token));
  //       // alert("bookingId" + " " + bookingId);
  //       NavigateFun(data);
  //       // console.log(data.userAccessToken);
  //       // if (Cookies.get("CBU").substring(13) === data.userAccessToken) {
  //       // alert(JSON.stringify(data.userAccessToken));
  //       // alert("inside");
  //       // if (
  //       //   data.bookingStatus === "ACCEPTED" ||
  //       //   data.bookingStatus === "ARRIVED"
  //       // ) {
  //       //   navigate(NavLinks.RIDE_CONFIRMED + "/" + data.bookingId);
  //       // } else if (data.bookingStatus === "STARTED") {
  //       //   navigate(NavLinks.START_RIDE + "/" + data.bookingId);
  //       // } else if (data.bookingStatus === "ENDED") {
  //       //   navigate(NavLinks.RIDE_REVIEW + "/" + data.bookingId);
  //       // }
  //       // }
  //     }
  //   );
  //   // notificationSocket.current.on("notifyAdmin", () => {
  //   //   setShowAlert(true);
  //   //   sound.play();
  //   // });
  //   notificationSocket.current.on(
  //     // "TkRjMU9UVTNZVEJrT0RNeE5XUmtNRGt6TWpnek5tUTVZakExWWpoa05ETm1aak5rT0RNMFlXSTVObU01WmprNFpUWmhNMkl4TXpCak1XUmhOMlF6WVE6ZXlKZmFXUWlPaUkyTXpGaFpEWXhabU5oTnpGbE16WTBPRGxsTm1VeFl6UWlMQ0owZVhCbElqb2lWVk5GVWlJc0ltUmxkbWxqWlZSNWNHVWlPaUpEYUhKdmJXVXZNVEV4TGpBdU1DNHdJaXdpY0d4aGRHWnZjbTBpT2lKTllXTWdUMU12ZFc1a1pXWnBibVZrSWl3aVkzSmxZWFJsWkVGMElqb2lNakF5TXkwd05DMHdOVlF3TmpvME1qb3lNQzQ1TXpOYUluMA",
  //     Cookies.get("CBU").substring(13),
  //     (data) => {
  //       alert("token" + JSON.stringify(data));
  //       setNotificationData("45");
  //       // if (data.action === "ESCORTARRIVED") {
  //       //   setShowAlert(true);
  //       //   setOnlyMeNotification([
  //       //     ...onlyMeNotification,
  //       //     {
  //       //       _id: data?.details?._id || "",
  //       //       firstName: data?.details?.user?.firstName || "",
  //       //       lastName: data?.details?.user?.lastName || "",
  //       //       phoneCode: data?.details?.user?.phone?.code || "",
  //       //       phoneNumber: data?.details?.user?.phone?.number || "",
  //       //       category: "SECURITY_END",
  //       //       createdAt: new Date(),
  //       //     },
  //       //   ]);
  //       //   // sound.play();
  //       // }
  //     }
  //   );
  //   // fetchData();
  // }, [bookingId]);

  const fetchBookingDeatils = async () => {
    //   bookingType: bookingType, //CONST_INSTANT,
    // alert(rideStatus);
    if (bookingId && rideDeatilsData.bookingType === CONST_INSTANT) {
      try {
        const { data } = await axios.post(
          A.HOST + A.TRACK_BOOKING,
          { bookingId: bookingId, bookingStatus: rideStatus },
          header
        );
        setRideStatus(data.data.bS);

        // console.log("header" + JSON.stringify(rideStatus));
        //   }
        // if (data.data.bS === "AWAITHING") {
        // setSearchingProfessional(true);
        navigate(NavLinks.SEARCHING_PROFESSIONALS + "/" + bookingId);
        // } else
        setSearchingProfessional(true);
        if (data.data.bS === "AWAITING") {
          // alert("retry");
          retry();
          clearInterval(intervalId);

          // if (each) {
          //   // clearInterval(intervalId);
          //   alert(each);
          // }
          // const retry = settings.bookingRetryCount;
          // if (initialCount < retry) {
          //   // clearInterval(retryInterval.current);
          //   // clearInterval(progressBarInterval.current);
          //   //   // setBarWidth(0);
          //   //   if (bookingAccepted === false) {
          //   //     //   setPage(2);
          //   //     setType("EXPIRED");
          //   //     // alert("EXPIRED");
          //   //     //   try {
          //   //     //     // await axios.post(
          //   //     //     //   A.HOST + A.ADMIN_BOOK_EXPIRY,
          //   //     //     //   {
          //   //     //     //     bookingId: id,
          //   //     //     //     denyType: "EXPIRED",
          //   //     //     //   },
          //   //     //     //   header
          //   //     //     // );
          //   //     //   } catch (err) {
          //   //     //     authFailure(err);
          //   //     //     alert(err);
          //   //     //   }
          //   //   }
          //   // } else {
          //   try {
          //     // alert("kyhjgnyjj");
          //     const result = await axios.post(
          //       A.HOST + A.USER_BOOK_RETRY,
          //       {
          //         bookingId: bookingId,
          //         radius: settings.requestDistance * CONST_MILES_VALUE,
          //       },
          //       header
          //     );
          //     const bookingStatus = result?.data?.data?.bS || "AWAITING";
          //     if (bookingStatus !== "AWAITING") {
          //       //bookingStatus
          //       // setCompleteStatus(true);
          //       fetchBookingDeatils();

          //       clearInterval(retryInterval.current);
          //       clearInterval(progressBarInterval.current);
          //       clearInterval(retry);
          //     }
          //   } catch (err) {
          //     clearInterval(retryInterval.current);
          //     clearInterval(progressBarInterval.current);
          //     // if (err.response.data.message === "NO PROFESSIONAL FOUND") setNoProfessional(true);
          //     if (err.response.data.code === 500)
          //       // setNoProfessional(true);
          //       setType("EXPIRED");
          //     //   setPage(2);
          //     // authFailure(err);
          //     navigate(NavLinks.HOME);

          //     clearInterval(retry);
          //   }
          //   initialCount = initialCount + 1;
          // } else {
          //   try {
          //     if (initialCount === retry) {
          //       const data = await axios.post(
          //         A.HOST + A.BOOKING_CANCEL_BEFORE_START_RIDE,
          //         {
          //           bookingId: bookingId,
          //           denyType: CONST_EXPIRED,
          //         },
          //         header
          //       );

          //       navigate(NavLinks.HOME);
          //       clearInterval(retry);
          //       initialCount = initialCount + 1;
          //     }
          //   } catch (err) {
          //     initialCount = initialCount + 1;
          //     clearInterval(retry);
          //   }
          // }
        }
        // else if (data.data.bS === "ACCEPTED" || data.data.bS === "ARRIVED") {
        //   //   alert(data.data.bS);
        //   //   navigate(
        //   //     NavLinks.RIDE_CONFIRMED +
        //   //       "/" +
        //   //   pickUp.selectedAddress.formatted_address ||
        //   //     pickUp.selectedAddress + "/" + drop.selectedAddress;
        //   //   );
        //   setSearchingProfessional(false);

        //   setRideStatus(data.data.bS);

        //   navigate(NavLinks.RIDE_CONFIRMED + "/" + bookingId);
        //   //   setRideAcepted(true);
        // } else if (data.data.bS === "STARTED") {
        //   setSearchingProfessional(false);

        //   navigate(NavLinks.START_RIDE + "/" + bookingId);
        // } else if (data.data.bS === "ENDED") {
        //   setSearchingProfessional(false);

        //   clearInterval(intervalId);
        //   setRideStatus(data.data.bS);

        //   //   setRideAcepted(false);
        //   //   setRideStatus(data.data.bS === "ENDED" ? true : false);

        //   navigate(NavLinks.RIDE_REVIEW + "/" + bookingId);
        // }
        else if (
          data.data.bS === "USERCANCELLED" ||
          data.data.bS === "USERDENY"
        ) {
          setSearchingProfessional(false);

          clearInterval(intervalId);
          navigate(NavLinks.RIDE_BOOKING);
        } else if (data.data.bS === "EXPIRED") {
          // setPop({ title: "No Professional Found ", type: "error" });
          setShow(true);
          setPopUpContent(language.NO_PROFESSIONALS_AVAILABLE);
        }
        // else {
        //   navigate(NavLinks.START_RIDE + "/" + bookingId);
        // }
        // clearInterval(intervalId);

        //   console.log(data);
      } catch (err) {
        clearInterval(intervalId);
        //   alert(err);
      }
    } else {
      navigate(NavLinks.SCHEDULE_RIDE_BOOKING + "/" + bookingId);
      clearInterval(intervalId);
    }
  };

  //     } catch (err) {
  //       alert(err);
  //     }
  //   };

  //   professionalAcceptBooking();
  //   }, [awaitingBookings]);

  useEffect(() => {
    // alert(popUpLogin);
    if (!header) {
      setPopUpLogin(true);
      // alert("fffffffff");
      //   header
    }
    // console.log(rideDeatilsData);
  }, []);

  useEffect(() => {
    // alert(rideAcepted);
    //   setUserValue(user);
    // alert(JSON.stringify(rideStatus));
    if (rideDetails) {
      fetchBookingDeatils();
      clearInterval(intervalId);

      intervalId = setInterval(fetchBookingDeatils, 10000);
    }
  }, [rideDetails]);

  // .............................
  const getCouponList = async () => {
    try {
      // if (header) {
      // alert("step___!");
      const { data } = await axios.post(
        A.HOST + A.GETCOUPON_LIST,
        {
          lat:
            rideDeatilsData?.pickUp?.results[0].geometry.location.lat ||
            couponLatlng.lat,
          lng:
            rideDeatilsData?.pickUp?.results[0].geometry.location.lng ||
            couponLatlng.lng,
          userId: user?.userId,
          serviceAreaId: rideDeatilsData?.categories?.serviceAreaID,
        },
        header
      );
      // alert("step___2");
      // console.log(data.data);

      setCoupon(data.data.response);
      // console.log(rideDeatilsData);
      // console.log(user?.userId);
      // console.log(rideDeatilsData?.categories?.serviceAreaID);
      // console.log(rideDeatilsData?.pickUp?.results[0].geometry.location.lng);
      // console.log(data);
      if (data.data.response) {
        data.data.response.map((each) => {
          // setPaymenTypeCoupon(
          //   each.couponPaymentType.filter((copuon) => copuon === paymentType)
          // );
          // console.log("coupon");
          // console.log(
          //   each.couponPaymentType.filter((copuon) => copuon === paymentType)[0]
          // );
          // console.log(paymentType);
          // console.log("coupon");
          // each.couponPaymentType.filter((couponPaymentType) => {
          //   // each.couponPaymentType.map(
          //   //     (payment) => payment === paymentType
          //   //   ) &&
          //   couponPaymentType.couponPaymentType === paymentType &&
          each.isAutomatic === true &&
          each.couponPaymentType.filter(
            (copuon) => copuon === paymentType
          )[0] === paymentType &&
          each.categoryIds.filter(
            (couponId) =>
              couponId === rideDeatilsData.vehicleCategory.categoryID
          )[0] === rideDeatilsData.vehicleCategory.categoryID
            ? setCouponId(each)
            : // : each.categoryIds.filter((couponId) => couponId) ===
              //   rideDeatilsData.vehicleCategory.categoryID
              // ? setCouponId(each)
              setCouponId("");
          // });
          // each.categoryIds.map((couponId) => couponId) ===
          //   rideDeatilsData.vehicleCategory.categoryID && setCouponId(each);
          // console.log(
          //   each.categoryIds.filter(
          //     (couponId) =>
          //       couponId === rideDeatilsData.vehicleCategory.categoryID
          //   )[0] === rideDeatilsData.vehicleCategory.categoryID
          // );
          // console.log(each);

          console.log(rideDeatilsData);
          // console.log(
          //   rideDeatilsData.categories.categories.map(
          //     (categoryid) => categoryid.categoryID
          //   )
          // );
        });
      }
      // }
    } catch (err) {
      if (err.response.data.code === 401) {
        // getCouponList();
        // alert("step___3");
      }
    }
  };

  // window.onhashchange = function () {
  //   if (window.innerDocClick) {
  //     console.log("Back__2");
  //     alert("Back__2");

  //     //Your own in-page mechanism triggered the hash change
  //   } else {
  //     //Browser back button was clicked
  //     console.log("Back");
  //     alert("Back");
  //   }
  // };
  useEffect(() => {
    // alert(JSON.stringify(header));
    //   // console.log(pickUp);
    // if (header) {
    getCouponList();
    // }
  }, [user?.userId, paymentType]);

  useEffect(() => {
    // alert(JSON.stringify(pickUp));
    // console.log(couponId);
    // alert(rideBooking.estimationPayableAmount);

    if (couponId) {
      // alert(couponId.couponAmount);
      // let couponAmount = 0;
      if (couponId.couponType === "FLAT") {
        couponAmount = couponId.couponAmount.toFixed(2);
      } else {
        couponAmount = (
          (rideDeatilsData.rideBooking.totalWithoutServiceTax / 100) *
          couponId.couponAmount
        ).toFixed(2);
      }
      // couponAmount = couponAmount;
      setFareAmount(
        (
          rideDeatilsData.rideBooking.estimationPayableAmount - couponAmount
        ).toFixed(2)
      );
    }
    // alert(fareAmount);
  }, [couponId]);

  // useEffect(() => {
  //   // alert(JSON.stringify(pickUp));
  // }, []);

  // ............Start...................

  // const retryRide = async (obj) => {
  //   if (obj.bookingStatus === "EXPIRED") {
  //     try {
  //       await axios.post(
  //         A.HOST + A.ADMIN_BOOK_RETRY_AWAITING,
  //         {
  //           bookingId: obj.id,
  //           // radius: selectedDistance * CONST_MILES_VALUE,
  //           radius:
  //             settings.requestDistance +
  //             settings.retryRequestDistance * retryCount,
  //         },
  //         header
  //       );
  //       // setAwaitingBookings([...awaitingBookings, obj.id]);
  //       // fetchTable((data) => data + 1);
  //       // setExpandFromParent(true);
  //       // setExpandFromParent(false);
  //     } catch (err) {
  //       // alert(parseError(err) === "NO PROFESSIONAL FOUND" ? language.NO_PROFESSIONALS_AVAILABLE : err);
  //       setPop({ title: language.NO_PROFESSIONALS_AVAILABLE, type: "error" });
  //     }
  //   }
  //   if (obj.bookingStatus === "AWAITING") {
  //     try {
  //       const { data } = await axios.post(
  //         A.HOST + A.ADMIN_BOOK_RETRY,
  //         {
  //           bookingId: obj.id,
  //           // radius: selectedDistance * CONST_MILES_VALUE,
  //           radius:
  //             settings.requestDistance +
  //             settings.retryRequestDistance * retryCount,
  //         },
  //         header
  //       );

  //       if (data.code === 200) {
  //         alert(200);
  //         // setAwaitingBookings([...awaitingBookings, obj.id]);
  //         // fetchTable((data) => data + 1);
  //         // setExpandFromParent(true);
  //         // setExpandFromParent(false);
  //       } else {
  //         setPop({ title: data.message, type: data.message });

  //         // alert(data.message);
  //       }
  //     } catch (err) {
  //       // setPop({ title: parseError(err) === "NO PROFESSIONAL FOUND" ? language.NO_PROFESSIONALS_AVAILABLE : err, type: "error" });
  //       setPop({ title: language.NO_PROFESSIONALS_AVAILABLE, type: "error" });
  //     }
  //   }
  // };

  // // setTimer((parseInt(settings.bookingRetryCount) + 1) * (parseInt(settings.driverRequestTimeout) * 1000 + extraTime));

  // // RETRY INTERVAL
  // const extraTime = U.RIDE_RETRY_WAIT;
  // useEffect(() => {
  // console.log("settings");
  // console.log(settings);
  // if (settings.bookingAlgorithm === "NEAREST") {
  //   setInterval(() => {
  //     retry();
  //   }, settings.nearestAlgorithm.bookingRequestCountForProfessional * 1000);
  // }
  // }, []);
  // ........
  const timeWait = +settings?.driverRequestTimeout * 1000 + 5000;
  let initialCount = 0;
  let retrycount = settings?.bookingRetryCount;
  const retry = async () => {
    if (bookingId) {
      // useEffect(() => {
      //   retryInterval.current = reInterval;
      // }, []);
      // console.log(settings);
      const reInterval = setInterval(async () => {
        retrycount =
          settings.bookingAlgorithm === "NEAREST" ? retrycount + 1 : retrycount;
        // retrycount = retrycount;
        // alert(JSON.stringify(retrycount));
        if (initialCount < retrycount) {
          // clearInterval(retryInterval.current);
          // clearInterval(progressBarInterval.current);
          //   // setBarWidth(0);
          //   if (bookingAccepted === false) {
          //     //   setPage(2);
          //     setType("EXPIRED");
          //     // alert("EXPIRED");
          //     //   try {
          //     //     // await axios.post(
          //     //     //   A.HOST + A.ADMIN_BOOK_EXPIRY,
          //     //     //   {
          //     //     //     bookingId: id,
          //     //     //     denyType: "EXPIRED",
          //     //     //   },
          //     //     //   header
          //     //     // );
          //     //   } catch (err) {
          //     //     authFailure(err);
          //     //     alert(err);
          //     //   }
          //   }
          // } else {
          try {
            // alert("kyhjgnyjj");
            const result = await axios.post(
              A.HOST + A.USER_BOOK_RETRY,
              {
                bookingId: bookingId,
                radius: distance,
              },
              header
            );
            const bookingStatus = result?.data?.data?.bS || "AWAITING";
            setDistance(distance + settings.retryRequestDistance);
            // alert(JSON.stringify(result));

            if (bookingStatus !== "AWAITING") {
              //bookingStatus
              // setCompleteStatus(true);
              // fetchBookingDeatils("retryfun");

              clearInterval(retryInterval.current);
              clearInterval(progressBarInterval.current);
              clearInterval(reInterval);
            }
            // else if (
            //   result?.data.data.bS === "ACCEPTED" ||
            //   result?.data.data.bS === "ARRIVED"
            // ) {
            //   navigate(NavLinks.RIDE_CONFIRMED + "/" + bookingId);
            // }
          } catch (err) {
            clearInterval(retryInterval.current);
            clearInterval(progressBarInterval.current);
            // if (err.response.data.message === "NO PROFESSIONAL FOUND") setNoProfessional(true);
            if (err.response.data.code === 500)
              // setNoProfessional(true);
              setShow(true);

            setPopUpContent(language.NO_PROFESSIONAL_AVAILABLE);

            setType("EXPIRED");
            //   setPage(2);
            // authFailure(err);
            // setPop({ title: "NO PROFESSIONAL FOUND", type: "error" });
            navigate(NavLinks.RIDE_BOOKING);

            clearInterval(reInterval);
          }
          initialCount = initialCount + 1;
        } else {
          try {
            if (initialCount === retrycount) {
              const data = await axios.post(
                A.HOST + A.BOOKING_CANCEL_BEFORE_START_RIDE,
                {
                  bookingId: bookingId,
                  denyType: CONST_EXPIRED,
                },
                header
              );
              setShow(true);
              setPopUpContent(language.NO_PROFESSIONALS_AVAILABLE);
              navigate(NavLinks.RIDE_BOOKING);
              // window.location.reload(true);

              // navigate(NavLinks.HOME);
              clearInterval(reInterval);
              initialCount = initialCount + 1;
            }
          } catch (err) {
            initialCount = initialCount + 1;
            clearInterval(reInterval);
          }
        }
      }, timeWait);
      retryInterval.current = reInterval;
      clearInterval(retry);
    }
  };

  // ............end...................
  const cancelRide = async () => {
    clearInterval(intervalId);

    const data = await axios.post(
      A.HOST + A.CANCEL_BOOKING,
      {
        bookingId: bookingId,
        // denyType: CONST_USERCANCELLED,
      },
      header
    );
    navigate(NavLinks.HOME);
  };
  if (show) {
    setTimeout(() => {
      setShow(false);
    }, 3500);
  }

  // useEffect(() => {
  //   console.log("rideDeatilsData");
  //   console.log(rideDeatilsData);
  // }, []);

  return (
    <>
      {/* {popup != null && (
        <PopUp
          unmount={() => setPop(null)}
          title={popup.title}
          type={popup.type}
        />
      )} */}

      <Modals
        show={show}
        onHide={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modals.Title className="text-center">{popUpContent}</Modals.Title>
      </Modals>
      <Notification />
      {mapArea ? (
        <div>
          {/* {rideDeatils !== true && rideAcepted === false ? ( */}
          <main className="zervx_main overflow-auto ">
            <div className="zervx_content overflow-auto">
              <section className="booking-method-outer row ">
                <div className="sticky top-0 z-50 bg-white">
                  <div className="header-arrow flex items-center col-lg-12">
                    <div className="text-start col-2 pr-0">
                      <Link to={NavLinks.HOME} className="mr-0">
                        <svg
                          className="ola-svg ola-icon  "
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <g>
                            <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                          </g>
                        </svg>
                      </Link>
                    </div>
                    <div className="text-end col-10 flex items-center justify-center">
                      <div>
                        <div className="  col-12 mx-auto text-center flex items-center justify-around ">
                          <div className="header-logon col-6 ">
                            <img
                              src={
                                rideDeatilsData?.vehicleCategory?.categoryImage
                              }
                              alt=""
                              className="w-20"
                            />
                          </div>
                          {/* <h6 className="mb-0"> - </h6> */}

                          <h6 className="mb-0 col-8 text-start px-0">
                            {rideDeatilsData?.vehicleCategory?.categoryName}
                          </h6>
                        </div>
                      </div>
                      {/* <div className="w-10 "></div> */}
                    </div>
                  </div>
                </div>

                <div className="map_outer">
                  <div
                    ref={mapArea}
                    className="bg-white  h-56 rounded-lg m-1"
                  ></div>
                </div>
                <div className="container">
                  <div className="row">
                    <div className="">
                      <div className="booking-method-content">
                        <ul>
                          <li className="pick_up_div grid grid-cols-4 gap-2">
                            <label>{language.PICKUP}</label>
                            <span className="col-span-3">
                              {/* {pickUp.selectedAddress ||
                              pickUp.selectedAddress.formatted_address} */}
                              <span className="pr-2">:</span>
                              {
                                rideDeatilsData.pickUp?.results[0]
                                  ?.formatted_address
                              }
                            </span>
                          </li>
                          <li className="pick_up_div grid grid-cols-4 gap-2">
                            <label>{language.DROP}</label>

                            <span className="col-span-3">
                              <span className="pr-2">:</span>

                              {rideDeatilsData.drop?.selectedAddress}
                            </span>
                          </li>

                          <li className="pick_up_div grid grid-cols-4 gap-2">
                            <label>{language.DISTANCE}</label>
                            <span className="col-span-3">
                              <span className="pr-2">:</span>

                              {(
                                rideDeatilsData?.estmationTravelDistance /
                                rideDeatilsData?.categories
                                  ?.distanceTypeUnitValue
                              ).toFixed(2) +
                                " " +
                                rideDeatilsData?.categories?.distanceType}
                            </span>
                          </li>

                          <li className="pick_up_div grid grid-cols-4 gap-2">
                            <label>{language.TIME}</label>
                            <span className="col-span-3">
                              <span className="pr-2">:</span>

                              {Math.round(
                                rideDeatilsData?.estmationTravelTime / 60
                              ) +
                                " Mins"}
                            </span>
                          </li>
                          <li className="pick_up_div grid grid-cols-4 gap-2">
                            {/* <div className="flex w-full "> */}
                            {/* <h3 className="  fair_outer flex items-end"> */}
                            <label>{language.FARE}</label>
                            <span
                              className={`${
                                couponId ? "line-through text-sm  " : "" //w-24,w-56
                              }`}
                            >
                              <span className="pr-2">:</span>

                              {rideDeatilsData?.categories?.currencySymbol +
                                " " +
                                rideDeatilsData?.rideBooking
                                  ?.estimationPayableAmount}
                            </span>
                            {/* </h3> */}

                            {couponId ? (
                              <div className="flex items-center">
                                <h6 className=" text-green-700 ">
                                  {/* {(couponId.couponType === "FLAT"
                                ? rideBooking.estimationPayableAmount -
                                  couponId.couponAmount
                                : (couponId.couponAmoun / 100) *
                                  rideBooking.estimationPayableAmount) +
                                " " +
                                categoriesData.currencySymbol} */}
                                  <div className="flex pr-2">
                                    <h5 className="">
                                      {rideDeatilsData?.categories
                                        ?.currencySymbol + " "}
                                    </h5>

                                    <h5>{fareAmount}</h5>
                                  </div>
                                  {/* {rideDeatilsData?.categories?.currencySymbol +
                                  " " +
                                  fareAmount} */}
                                </h6>
                                <span className=" text-green-700 text-sm font-semibold">
                                  <div className="flex  md:text-xl  text-xs">
                                    <p className=" text-green-700 ">
                                      {couponId.couponCode + " "}
                                    </p>
                                    <p className="text-green-700">{language.COUPON_APPLIED}</p>
                                  </div>
                                  {/* {couponId.couponCode + " Applied"} */}
                                </span>
                              </div>
                            ) : null}
                            {/* </div> */}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section className="booking-method-confirmation">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="bookin-form">
                        <div className="flex items-center font-bold  col-span-2 mt-2 bg-gray-200 pr-2">
                          {/*input-field-common */}
                          <label className="mx-2 mr-4  w-[55px] my-auto p-2">
                            {language.PAYBY}
                          </label>
                          {/* <input type="text" id="name" /> */}
                          <DropdownNormal
                            fields={rideDeatilsData?.categories?.paymentMode}
                            // fields={PAYBY}
                            change={(e) => {
                              setPaymentType(e);
                              if (e === "CARD") {
                                navigate(NavLinks.CARD_DEATILS + "/booking");
                              }
                            }}
                            defaultValue={paymentType}
                            className="bg-white"
                          />
                        </div>
                        {isEnableCoupon === 0 && (
                          <div className="flex items-center font-bold  col-span-2 mt-2 bg-gray-200 pr-2 ">
                            {/*input-field-common */}
                            <label className="mx-2 mr-4  w-[55px] my-auto p-2 ">
                              {language.COUPONS}
                            </label>
                            {header && (
                              <>
                                {isEnableCoupon === 0 ? (
                                  <div
                                    className="flex items-center justify-between w-full cursor-pointer"
                                    onClick={() => {
                                      isEnableCoupon === 0
                                        ? setIsEnableCoupon(1)
                                        : setIsEnableCoupon(0);
                                    }}
                                  >
                                    {/* <span>Coupon</span> */}
                                    <div className=" ">
                                      <div>
                                        <div
                                          className={`p-2 bg-gray-200  cursor-pointer  ${
                                            couponId?.couponCode
                                              ? "text-green-600"
                                              : "text-gray-400"
                                          }`}
                                          onClick={() => {
                                            setIsEnableCoupon(0);
                                          }}
                                        >
                                          {couponId?.couponCode
                                            ? couponId?.couponCode
                                            : "Select Coupon"}
                                        </div>
                                      </div>
                                    </div>
                                    <VscChevronDown />
                                  </div>
                                ) : (
                                  <></>
                                  // <div
                                  //   className="flex items-center justify-between w-full cursor-pointer"
                                  //   onClick={() => {
                                  //     isEnableCoupon === 0
                                  //       ? setIsEnableCoupon(1)
                                  //       : setIsEnableCoupon(0);
                                  //   }}
                                  // >
                                  //   <div
                                  //     className=" "
                                  //     onClick={() => {
                                  //       setIsEnableCoupon(0);
                                  //     }}
                                  //   >
                                  //     <div
                                  //       className="p-2 bg-gray-200 cursor-pointer"
                                  //       onClick={() => {
                                  //         setIsEnableCoupon(0);
                                  //       }}
                                  //     >
                                  //       Select Coupon
                                  //     </div>
                                  //   </div>
                                  //   <VscChevronDown />
                                  // </div>
                                )}
                                {/* <BiChevronDown /> */}
                              </>
                            )}
                          </div>
                        )}
                        {/* <div className=" font-bold  grid grid-cols-4 mt-2 pick_up_div  ">
                        <label className="flex items-center py-1 ">
                          Coupon
                        </label>
                        <div className=" col-span-3  w-full ">
                          {header && (
                            <>
                              {isEnableCoupon === 0 ? (
                                <>
                                  {/* <span>Coupon</span> *
                                  <div
                                    className=" "
                                    onClick={() => {
                                      setIsEnableCoupon(1);
                                    }}
                                  >
                                    <div>
                                      <div
                                        className={`p-2 bg-gray-200  cursor-pointer  ${
                                          couponId?.couponCode
                                            ? "text-green-600"
                                            : "text-gray-400"
                                        }`}
                                        onClick={() => {
                                          setIsEnableCoupon(0);
                                        }}
                                      >
                                        {couponId?.couponCode
                                          ? couponId?.couponCode
                                          : "Select Coupon"}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    className=" "
                                    onClick={() => {
                                      setIsEnableCoupon(0);
                                    }}
                                  >
                                    <div
                                      className="p-2 bg-gray-200 cursor-pointer"
                                      onClick={() => {
                                        setIsEnableCoupon(0);
                                      }}
                                    >
                                      Select Coupon
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div> */}
                        {header ? (
                          <div className="flex items-center col-span-2 mt-2  ">
                            {/* <div className="  w-full">
                          <TextField className="" />
                        </div> */}
                            <div className=" w-full ">
                              {coupon && isEnableCoupon === 1
                                ? coupon.map(
                                    (each) =>
                                      each.couponPaymentType.filter(
                                        (copuon) => copuon === paymentType
                                      )[0] === paymentType && (
                                        <div
                                          className="p-2 bg-blue-200 my-2 rounded-xl cursor-pointer  focus:bg-blue-400 grid grid-rows-3 gap-1 shadow-md "
                                          onClick={() => {
                                            setCouponId(each);
                                            setIsEnableCoupon(0);
                                          }}
                                        >
                                          <span className="font-semibold ">
                                            {each.couponTitle}
                                          </span>
                                          <span className=" text-[11px] text-gray-500 px-2">
                                            {each.couponCodeMessage}
                                          </span>

                                          <div className="px-2 text-sm flex justify-between">
                                            <span className="border-2 p-1 rounded-lg border-blue-600 border-dotted text-green-600 font-bold bg-slate-100">
                                              {each.couponCode}
                                            </span>
                                            {/* <span className=" text-red-600 font-semibold">
                                        {format(
                                          new Date(each.vaildTo),
                                          "d MMM yyy p"
                                        )}
                                      </span> */}
                                          </div>
                                        </div>
                                      )
                                  )
                                : null}
                            </div>
                          </div>
                        ) : null}
                        {
                          rideDeatilsData?.rideBooking?.surge.isNightTiming ===
                            true &&
                          rideDeatilsData?.rideBooking?.surge.isPeakTiming ===
                            true ? (
                            <div className=" mt-4 bg-yellow-200 rounded-lg text-center">
                              <p className="p-2 text-xs">
                                <span className="pr-1">
                                  {language.HIGH_PRICE}
                                </span>
                                {(
                                  rideDeatilsData?.rideBooking?.surge
                                    .nightTimeFare +
                                  rideDeatilsData?.rideBooking?.surge
                                    .peakTimeFare
                                ).toFixed(2)}
                                {language.HIGH_PRICE_DEMAND_MESSAGE}
                              </p>
                            </div>
                          ) : (
                            (rideDeatilsData?.rideBooking?.surge
                              .isNightTiming === true ||
                              rideDeatilsData?.rideBooking?.surge
                                .isPeakTiming === true) && (
                              <div className=" mt-4 bg-yellow-200 rounded-lg text-center">
                                <p className="p-2 text-xs">
                                  <span className="pr-1">
                                    {language.HIGH_PRICE}
                                  </span>
                                  {rideDeatilsData?.rideBooking?.surge
                                    .isNightTiming
                                    ? rideDeatilsData?.rideBooking?.surge.nightTimeFare.toFixed(
                                        2
                                      )
                                    : rideDeatilsData?.rideBooking?.surge.peakTimeFare.toFixed(
                                        2
                                      )}
                                  {language.HIGH_PRICE_DEMAND_MESSAGE}
                                </p>
                              </div>
                            )
                          )
                          // ) : (
                          //   rideDeatilsData?.rideBooking?.surge.isPeakTiming ===
                          //     true && (
                          //     <div className=" mt-4 bg-yellow-200 rounded-lg text-center">
                          //       <p className="p-2 text-xs">
                          //         <span className="pr-1">
                          //           Price is Currenty high
                          //         </span>
                          //         {rideDeatilsData?.rideBooking?.surge
                          //           .peakTimeFare > 0
                          //           ? rideDeatilsData?.rideBooking?.surge
                          //               .peakTimeFare
                          //           : ""}
                          //         x due to high demand and give you priority
                          //       </p>
                          //     </div>
                          //   )
                          // )
                        }
                        {/* rideDeatilsData?.categories?.surge?.peakTimeFare >
                              0
                            ? rideDeatilsData?.categories?.surge?.peakTimeFare
                            : "00"} */}
                        {JSON.parse(localStorage.getItem("CBU")) &&
                        paymentType ? (
                          // !couponId
                          <>
                            <input
                              type="submit"
                              id="submit"
                              value={language.CONTINUE}
                              className="my-10 mb-20"
                              onClick={() => {
                                // console.log(user);
                                if (header) {
                                  bookRide();
                                }
                              }}
                            />
                          </>
                        ) : (
                          paymentType && (
                            // couponId
                            <input
                              type="submit"
                              id="submit"
                              value={language.CONTINUE}
                              className="my-10 mb-20"
                              onClick={() => {
                                // console.log(user);
                                // if (header) {
                                navigate(NavLinks.LOG_IN + "/loginBeforeRide");
                                // }
                              }}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div
              className="zervx_rightimg"
              // style="background-image: url('images/bg_city.jpg');"
            >
              <img
                src={settings?.bookingAppImage}
                alt=""
                className="w-screen h-screen"
              />

              <div className="global-img-right">
                {/* <h2>
                Ride with <span>Comfort</span>
              </h2>
              <h2>
                Ride with <span>ZervX</span>
              </h2> */}
                <h2>{settings?.bookingAppHomePageMessage}</h2>
              </div>
            </div>
          </main>
        </div>
      ) : (
        <SmallLoader />
      )}
    </>
  );
}
