import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import NavLinks from "../../utils/navLinks";
import axios from "axios";
import { useParams } from "react-router";
import { format } from "date-fns";
import { Loader } from "@googlemaps/js-api-loader";

import {
  FiThumbsDown,
  FiKey,
  FiPhone,
  FiStar,
  FiPercent,
  FiLifeBuoy,
  FiTriangle,
} from "react-icons/fi";

import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import A from "../../utils/urls.utils";
import useAdmin from "../hooks/useAdmin";
import greenIMG from "../../assets/images/green-dot.png";
import customer_service from "../../assets/images/customer-service.png";
import { SmallLoader } from "../common/SmallLoader";

export default function YourRideDeatils() {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const { header, user } = useAdmin();
  const { bookingId } = useParams();
  const mapArea = React.useRef();

  const [googleLoaded, setGoogleLoaded] = useState(false);
  const [map, setMap] = useState(null);

  const [bookingDeatils, setBookingDeatils] = useState({
    id: "",
    pickUp: null,
    drop: null,
    firstName: "",
    lastName: "",
    bookingID: "",
    bookingStatus: "",
    bookingOTP: "",
    avatar: "",
    plateNumber: "",
    model: "",
    color: "",
    vehicleCategory: "",
    avgRating: "",
    categoryImage: "",
    pickUpLat: 0,
    pickUpLng: 0,
    dropLat: "",
    dropLng: "",
    bookingDate: "",
    amount: 0,
    currencySymbol: "",
    professionalImg: "",
    professionalRating: [],
  });

  const getBookingDetailById = async () => {
    try {
      // alert(JSON.stringify("bookingId" + bookingId));

      const { data } = await axios.post(
        A.HOST + A.GET_ACCEPT_BOOKING,
        { bookingId: bookingId },
        header
      );
      console.log(data.data);
      //   setBookedInformation(data.data);
      setBookingDeatils({
        id: data.data._id,
        pickUp: data.data.activity.rideStops[0].addressName,
        drop: data.data.activity.rideStops[1].addressName,
        firstName: data.data.professional.firstName,
        lastName: data.data.professional.lastName,
        bookingID: data.data.bookingId,
        bookingOTP: data.data.bookingOTP,
        avatar: data.data.professional.avatar,
        plateNumber: data.data.vehicle.plateNumber,
        bookingType: data.data.bookingType,
        bookingStatus: data.data.bookingStatus,
        dropLat: data.data.activity.rideStops[1].lat,
        dropLng: data.data.activity.rideStops[1].lng,
        pickUpLat: data.data.activity.rideStops[0].lat,
        pickUpLng: data.data.activity.rideStops[0].lng,
        vehicleCategory: data.data.vehicle.vehicleCategoryName,
        model: data.data.vehicle.model,
        categoryImage: data.data.vehicle.vehicleCategoryImage,
        bookingDate: format(new Date(data.data.bookingDate), "d MMM yyy p"),
        amount: data.data.invoice.payableAmount,
        currencySymbol: data.data.currencySymbol,
        professionalImg: data.data.professional.avatar,
        professionalRating: [data.data.professional.avgRating],
      });
      //   alert(data.data.professional.review.avgRating);
      // alert(JSON.stringify(data.data.destination.addressName));
      console.log(data.data);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    getBookingDetailById();
  }, [bookingId]);

  React.useEffect(() => {
    if (googleLoaded) {
      const googleMap = new window.google.maps.Map(mapArea.current, {
        // center: U.mode === "pamworld" ? { lat: 9.476411, lng: 6.600669 } : { lat: 8.9956441, lng: 38.7816384 },
        center: {
          lat: bookingDeatils.pickUpLat || 28.6134594,
          lng: bookingDeatils.pickUpLng || 77.21191,
          //   lat: 28.6134594,
          //   lng: 77.21191,
        },
        zoom: 14,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
      });
      setMap(googleMap);

      // const pickUpMarker = new window.google.maps.Marker({
      //   position: {
      //     lat: settings?.location?.lat,
      //     lng: settings?.location?.lng,
      //   },
      //   map: map,
      // });
    }
  }, [googleLoaded]);

  useEffect(() => {
    if (settings?.mapApi?.web) {
      const loader = new Loader({
        apiKey: settings?.mapApi.web,
        version: "weekly",
        libraries: ["places"],
      });
      loader
        .load()
        .then(() => {
          setGoogleLoaded(true);
        })
        .catch((e) => {});
    }
  }, [settings?.mapApi?.web]);

  useEffect(() => {
    if (bookingDeatils.dropLat && bookingDeatils.dropLng) {
      new window.google.maps.Marker({
        icon: {
          url: greenIMG,
          scaledSize: new window.google.maps.Size(35, 35),
        },
        position: {
          lat: bookingDeatils.dropLat,
          lng: bookingDeatils.dropLng,
          //     lat: drop.results[0].geometry.location?.lat,
          //     lng: drop.results[0].geometry.location?.lng,
        },
        map: map,
      });
    }
  }, [map]);
  return (
    <>
      <main className="zervx_main">
        {bookingDeatils ? (
          <div className="zervx_content">
            <section className="header-outer">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-md-3 col-3">
                    <div className="header-arrow">
                      <Link to={NavLinks.YOUR_RIDES}>
                        <svg
                          className="ola-svg ola-icon"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                        >
                          <g>
                            <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                          </g>
                        </svg>
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-6 col-6">
                    <div className="header-logo text-center">
                      <h5>{bookingDeatils.bookingID}</h5>
                    </div>
                  </div>
                  <div className="col-md-3 col-3">
                    {/* <!-- <div className="header-login">
                        <i className="fa fa-user" aria-hidden="true"></i> <a href="#">Login</a>

                     </div> --> */}
                  </div>
                </div>
              </div>
            </section>

            {/* <!-- Receipt_START --> */}

            <section className="your_ride_details">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <div className="your_ride_details_inner">
                      <div className="map_otr">
                        <div className="map">
                          <div
                            ref={mapArea}
                            className="bg-white  h-56 rounded-lg m-1"
                          ></div>
                          {/* <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d25895663.485556163!2d-95.665!3d37.599999999999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54eab584e432360b%3A0x1c3bb99243deb742!2sUnited%20States!5e0!3m2!1sen!2sin!4v1677685791753!5m2!1sen!2sin"
                        width="100%"
                        style="border:0;"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe> */}
                        </div>
                        <div className="map_overlay">
                          <h6>{bookingDeatils.vehicleCategory}</h6>
                          <p>{bookingDeatils.model}</p>
                          <h5>{bookingDeatils.plateNumber}</h5>

                          <div className="car_icon">
                            <img
                              src={bookingDeatils.categoryImage}
                              alt=""
                              title=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="date_price">
                        <div className="">
                          {/* {format(
                        new Date(bookingDeatils.bookingDate),
                        "d MMM yyy p"
                      )} */}
                          {bookingDeatils.bookingDate}
                        </div>
                        <div className="price">
                          <h5>
                            {bookingDeatils.currencySymbol +
                              " " +
                              bookingDeatils.amount}
                          </h5>
                          <p>{bookingDeatils.bS}</p>
                          {/* Paid */}
                        </div>
                      </div>
                      <div className="trip_location">
                        <h5>{language.TRIP_LOCATION}</h5>

                        <div className="trip_div start">
                          {bookingDeatils.pickUp}
                        </div>
                        <div className="trip_div end">
                          {bookingDeatils.drop}
                        </div>
                      </div>

                      <div className="user_outer">
                        <div className=" ">
                          <img
                            src={bookingDeatils.professionalImg}
                            alt=""
                            title=""
                            className="user rounded-ful w-20 h-20"
                          />
                        </div>
                        <div className="user_details">
                          <h5>
                            {bookingDeatils.firstName +
                              " " +
                              bookingDeatils.lastName}
                          </h5>

                          <ul>
                            {/* {bookingDeatils.professionalRating.map((each) => ( */}
                            <li
                              className={`${
                                bookingDeatils.professionalRating >= 1
                                  ? "color"
                                  : ""
                              }`}
                            >
                              <i className="fa fa-star">
                                <FiStar />
                              </i>
                            </li>
                            {/* ))} */}
                            <li
                              className={`${
                                bookingDeatils.professionalRating >= 2
                                  ? "color"
                                  : ""
                              }`}
                            >
                              <i className="fa fa-star">
                                <FiStar />
                              </i>
                            </li>
                            <li
                              className={`${
                                bookingDeatils.professionalRating >= 3
                                  ? "color"
                                  : ""
                              }`}
                            >
                              <i className="fa fa-star">
                                <FiStar />
                              </i>
                            </li>
                            <li
                              className={`${
                                bookingDeatils.professionalRating >= 4
                                  ? "color"
                                  : ""
                              }`}
                            >
                              <i className="fa fa-star">
                                <FiStar />
                              </i>
                            </li>
                            <li
                              className={`${
                                bookingDeatils.professionalRating >= 5
                                  ? "color"
                                  : ""
                              }`}
                            >
                              <i className="fa fa-star">
                                <FiStar />
                              </i>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <Link
                        to={
                          NavLinks.YOUR_RIDE_VIEW_RECEIPT +
                          "/" +
                          bookingDeatils.id
                        }
                      >
                        {bookingDeatils.bookingStatus === "ENDED" ? (
                          <div className="w-full flex justify-center text-black hover:no-underline text-center mt-8 cursor-pointer">
                            <div className=" bg-indigo-200 font-semibold text-center flex justify-center items-center rounded-xl p-2 py-auto w-56">
                              <button className=" flex items-center">
                                {language.VIEW_RECEIPT}
                              </button>
                            </div>
                          </div>
                        ) : null}
                      </Link>
                      {/* <div className="get_help_otr mb-5">
                    <div className="help_icon">
                      <img src={customer_service} alt="" title=" " />
                    </div>

                    <div className="help_content">
                      <h6>Get Trip Help</h6>
                      <p>Need help for someting else? Find it here</p>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        ) : (
          <SmallLoader />
        )}
        <div
          className="zervx_rightimg"
          // style="background-image: url('images/bg_city.jpg');"
        >
          <img
            src={settings?.bookingAppImage}
            alt=""
            className="w-screen h-screen"
          />

          <div className="global-img-right">
            {/* <h2>
            Ride with <span>Comfort</span>
          </h2>
          <h2>
            Ride with <span>ZervX</span>
          </h2> */}
            <h2>{settings?.bookingAppHomePageMessage}</h2>
          </div>
        </div>
      </main>
    </>
  );
}
