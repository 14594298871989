import React from "react";

export const TextField = ({
  assignDefaultValue = () => {}, // assing only for defualt value.
  width = "full",
  error = "",
  type = "text",
  required = false,
  icon = "",
  hint = "",
  iconSelect = () => {},
  change = () => {},
  placeholder = "",
  className = "",
  padding = true,
  margin = 0,
  align = "",
  ref = null,
  readOnly = false,
  value,
  autofocus = false,
  disabled = false,
  maxlength = 100,
  ...rest
}) => {
  const direction = document
    .getElementsByTagName("html")[0]
    .getAttribute("dir");
  return (
    <div div className="block ">
      <div
        className={`flex w-${width} text-center overflow-hidden bg-gray-200  ${
          document.getElementsByTagName("html")[0].getAttribute("dir") === "ltr"
            ? `mr-${margin}`
            : `ml-${margin}`
        }`}
      >
        {icon !== "" && error === "" && (
          <div
            onClick={iconSelect}
            className={`text-sm  cursor-pointer text-black ${
              direction === "ltr" ? "right-0" : "left-0"
            } text-gray-400 flex justify-center items-center  ${
              error !== "" && "border-red-500"
            }`}
          >
            <span
              className={` items-center px-2 border-gray-100 ${
                //dark:bg-gray-900
                error !== "" && "border-red-500"
              }`}
            >
              {icon}
            </span>
          </div>
        )}
        <input
          disabled={disabled}
          readOnly={readOnly}
          ref={ref}
          onChange={(e) => change(e.target.value)}
          type={type}
          onWheel={(e) => e.currentTarget.blur()}
          name=""
          value={value}
          required={required}
          placeholder={placeholder ?? value}
          autoFocus={autofocus}
          maxLength={maxlength}
          // className={
          //   className +
          //   ` w-full shadow ${
          //     align === "center" && "text-center"
          //   }   text-sm focus:outline-none  rounded-xl p-3 pl-1 border-b-2 border-gray-100 bg-gray-100 ${
          //     //border-b-2 border-gray-100 dark:border-gray-800
          //     //dark:bg-gray-900
          //     readOnly === true
          //       ? " bg-gray-300 cursor-not-allowed  shadow_blue " //border-blue-800
          //       : " border-b-2 dark:focus:border-blue-800 border-blue-800 shadow_blue bg-gray-100" //
          //   } ${
          //     error !== "" &&
          //     " border-2 placeholder-red-500 dark:placeholder-white shadow_red"
          //   }` //border-red-500
          // }
          className={
            className +
            ` w-full p-2   ${
              align === "center" && "text-center"
            } focus:outline-none bg-gray-200 `
          }
          id=""
          {...rest}
        />
        {/* {hint && error === "" && (
          <div className="w-full flex">
            <div className="mx-2">
              {hint && (
                <p
                  className="text-gray-400 dark:text-gray-500 text-sm mt-1 "
                  style={{ fontSize: 11 }}
                >
                  {hint}
                </p>
              )}
            </div>
          </div>
        )}
        {error !== "" && (
          <div className="flex">
            <div className="mx-2">
              {error && (
                <p
                  className="text-red-600 dark:text-red-500 text-sm mt-1 "
                  style={{ fontSize: 11 }}
                >
                  {error}
                </p>
              )}
            </div>
          </div>
        )} */}
      </div>
      {hint && error === "" && (
        <div className="flex">
          <div className="mx-2">
            {hint && (
              <span
                className="text-gray-400 dark:text-gray-500 text-sm mt-1 "
                style={{ fontSize: 11 }}
              >
                {hint}
              </span>
            )}
          </div>
        </div>
      )}
      {error !== "" && (
        <div className="flex">
          <div className="mx-2">
            {error && (
              <span
                className="text-red-600 dark:text-red-500 text-sm mt-1 "
                style={{ fontSize: 11 }}
              >
                {error}
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
