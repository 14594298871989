import React, { useState } from "react";
import { FiChevronDown } from "react-icons/fi";

export const DropdownNormal = ({
  width = "full",
  error = "",
  type,
  icon = <FiChevronDown />,
  hint = "",
  fields = [],
  change = () => {},
  placeholder,
  defaultValue = "",
  className,
  selectText = "",
  disabled = false,
  readOnly = false,
  ...rest
}) => {
  //   const { language } = useLanguage();

  return (
    <div className="flex flex-col w-full">
      {/* // <div className={`relative w-${width}`}> */}
      <select
        disabled={disabled}
        onChange={(e) => {
          change(e.target.value, e.target.selectedIndex - 1);
        }}
        // className={`w-${width}  outline-none shadow ${
        //   //dark:bg-gray-900
        //   readOnly === true && " bg-gray-200 cursor-not-allowed"
        // } rounded-xl px-4 py-3 border-b-2 border-gray-100 bg-white dark:border-gray-800 shadow_blue text-sm dark:focus:border-blue-800 cursor-pointer ${
        //   error && "border-2  dark:border-red-500 shadow_red" //border-red-500
        // }`}
        className={`w-${width}  outline-none${
          //dark:bg-gray-900
          readOnly === true && " bg-gray-200 cursor-not-allowed"
        }  p-2 w-full focus:outline-none bg-gray-200  ${
          error && "border-2  dark:border-red-500 shadow_red" //border-red-500
        }`}
        value={
          fields.label
            ? fields?.find((each) => {
                return (
                  each?.label === defaultValue || each?.value === defaultValue
                );
              })?.value || ""
            : fields?.find((each) => {
                return each === defaultValue || each === defaultValue;
              })
        }
      >
        {/* <optgroup className="h-72"> */}
        {/* <option value="">{selectText !== "" ? selectText : "Select"}</option> */}
        {fields.map((field, idx) =>
          field.label ? (
            <option data-idx={idx} id={idx} key={idx} value={field.value}>
              {field.label}
            </option>
          ) : (
            <option value={field} id={idx} key={idx}>
              {field.toString().toLowerCase()}
            </option>
          )
        )}
        {/* </optgroup> */}
      </select>
      {/* {icon && (
        <div
          className={`absolute ${
            direction === "ltr" ? "right-0" : "left-0"
          } top-0 bottom-0 text-gray-500 flex justify-center items-center mx-3 rounded-xl ${
            error != "" && "text-red-800"
          }`}
        >
          {icon}
        </div>
      )} */}
      {!!hint === true && !!error === true && (
        <div className="flex">
          <div className="mx-2">
            <p
              className="text-gray-300 dark:text-gray-500 text-sm mt-1"
              style={{ fontSize: 11 }}
            >
              {hint}
            </p>
          </div>
        </div>
      )}
      {!!error === true && (
        <div className="flex">
          <div className="mx-2">
            <p
              className="text-red-600 dark:text-red-500 text-sm mt-1"
              style={{ fontSize: 11 }}
            >
              {error}
            </p>
          </div>
        </div>
      )}
      {/* // </div> */}
    </div>
  );
};
