import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FiCast, FiMenu } from "react-icons/fi";
import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import { FaCar, FaReceipt, FaUser } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import NavLinks from "../../utils/navLinks";
import { CgLogIn } from "react-icons/cg";
import { Loader } from "@googlemaps/js-api-loader";

export default function NavBar({ path = "", bookingId }) {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const navigate = useNavigate();
  // const { path } = useParams();

  const [loginToken, setLoginToken] = useState(null);
  const [googleLoaded, setGoogleLoaded] = useState(false);

  useEffect(() => {
    setLoginToken(
      Cookies.get("CBU") || JSON.parse(localStorage.getItem("CBU"))
    );
    // alert(Cookies.get("CBU") + "______" + loginToken);
  }, []);
  useEffect(() => {
    // console.log(loginToken);
  }, [loginToken]);
  // useEffect(() => {
  //   // console.log("------------");
  //   // console.log(settings);
  //   // console.log("------------");
  //   if (settings?.mapApi?.web !== null) {
  //     // console.log(JSON.stringify(settings));
  //     const loader = new Loader({
  //       apiKey: settings?.mapApi?.web,
  //       // apiKey: "AIzaSyBbdeSXbRBE0Ts6G8q4XR0uoa8o5KYlnDI",

  //       version: "weekly",
  //       libraries: ["places"],
  //     });
  //     loader
  //       .load()
  //       .then(() => {
  //         setGoogleLoaded(true);
  //       })
  //       .catch((e) => {});
  //   }
  //   // console.log("settings");
  //   // console.log(settings);
  // }, [settings, settings?.mapApi?.web]);
  return (
    <section className="header-outer">
      <div className="container">
        <div className="row align-items-center justify-between px-2">
          <div className="col-md-4 col-4">
            {/* <div className=""> */}
            {/* <i className="fa fa-long-arrow-left" aria-hidden="true"></i> */}
            {!!loginToken ? (
              <button
                className="btn btn-secondary border-2"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasScrolling"
                aria-controls="offcanvasScrolling"
              >
                <FiMenu />
              </button>
            ) : (
              <button></button>
            )}
            <div
              className="offcanvas offcanvas-start"
              data-bs-scroll="true"
              data-bs-backdrop="false"
              tabIndex="-1"
              id="offcanvasScrolling"
              aria-labelledby="offcanvasScrollingLabel"
            >
              {/* <div className="overlayTwo"> */}
              {/* <div className="location-permission-outer"> */}
              <div className="offcanvas-header">
                {/* <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
              Colored with scrolling
            </h5> */}
                <img src={settings?.mobileLogo} alt="" className="h-10 " />

                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="">
                {/* //offcanvas-body */}
                {/* <ol className=""> */}
                {/* <Link to={NavLinks.HOME}> */}
                <div
                  className="p-2 px-4 bg-gray-50 hover:bg-gray-100  my-1 flex cursor-pointer font-bold  text-black"
                  // onClick={(e) => {
                  //   alert(JSON.stringify(e));
                  //   navigate(NavLinks.HOME);
                  // }}
                  data-bs-dismiss="offcanvas"
                >
                  <div className="px-2 py-1">
                    <FaCar />
                  </div>
                  {language.BOOKING_RIDES}
                  {/* Booking Rides */}
                </div>
                {/* </Link> */}

                <Link to={NavLinks.YOUR_RIDE_VIEW_UCOMING_RIDES}>
                  <div className="p-2 px-4 bg-gray-50 hover:bg-gray-100 mb-1 flex font-bold cursor-pointer text-black">
                    <div className="px-2 py-1">
                      <FaReceipt />
                    </div>
                    <span>{language.UPCOMING_RIDES}</span>
                    {/* View Receipt */}
                  </div>
                </Link>
                {/* </ol> */}
              </div>
              <Link to={NavLinks.YOUR_RIDES}>
                <div className="p-2 px-4 bg-gray-50 hover:bg-gray-100  mb-1 flex font-bold cursor-pointer text-black">
                  <div className="px-2 py-1">
                    <FiCast />
                  </div>
                  {language.YOUR_RIDES}
                  {/* Your Rides */}
                </div>
              </Link>
            </div>
            {/* </div> */}
            {/* <h1>sdksndjs</h1> */}
          </div>
          <div className="col-md-2 col-2 fixed img-fluid">
            <div className="header-logo">
              {/* <img src={zervxpng} alt="" /> */}
              <img src={settings?.mobileLogo} alt="" />
            </div>
          </div>
          <div className="col-md-4 col-4 fixed">
            <div className="header-login ">
              {/* <i className="fa fa-user" aria-hidden="true"></i> */}

              {/* {userProfile === "" ? ( */}
              {/* {alert(loginToken)} */}

              {loginToken === null ||
              loginToken === undefined ||
              loginToken === "null" ? (
                <>
                  {/* {alert(111)} */}
                  <div
                    className=" flex justify-end cursor-pointer md:text-xl text-sm"
                    onClick={() => {
                      // setLoginOn(true);
                      navigate(NavLinks.LOG_IN + "/HomeLogin");
                    }}
                  >
                    <div className="">{language.LOG_IN}</div>
                    <div className="p-1 flex items-center">
                      <CgLogIn />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className=" flex justify-end cursor-pointer"
                    // onClick={adminLogout}
                    onClick={() => {
                      navigate(NavLinks.PROFILE + "/" + path + "/" + bookingId);
                    }}
                  >
                    <div className="pt-1">
                      <FaUser />
                    </div>
                    {/* <div className="">{Language.LOG_OUT}</div> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
