import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { IoWallet } from "react-icons/iow";
// import { IoWallet } from "react-icons/iow";

import { FcInvite } from "react-icons/fc";
import { SiSololearn, SiLiberapay } from "react-icons/si";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { useFormik } from "formik";
// import * as yup from "yup";

import useSettings from "../hooks/useSettings";
import useLanguage from "../hooks/useLanguage";
import NavLinks from "../../utils/navLinks";
import useAdmin from "../hooks/useAdmin";
import A from "../../utils/urls.utils";
import { FaWallet, FaUser } from "react-icons/fa";
import { HiChevronRight } from "react-icons/hi";
import profileAvathar from "../../assets/images/profile.png";
import { SmallLoader } from "../common/SmallLoader";
import Modal from "react-bootstrap/Modal";

import { Button } from "react-bootstrap";

//import useSocket from "../hooks/useSocket";

export default function Profile() {
  const { settings } = useSettings();
  const { language } = useLanguage();
  const { path, bookingId } = useParams();
  const { header, adminLogout, userProfileFetch } = useAdmin();
  const navigate = useNavigate();

  //const { notificationSocket } = useSocket();

  const [user, setUser] = useState();
  const [modalShow, setModalShow] = useState(false);
  const userProfile = async () => {
    try {
      // alert(JSON.stringify(header));
      const { data } = await axios.post(
        A.HOST + A.USER_PROFILE_GET,
        {},
        header
      );
      // console.log(data.data.avatar);
      // alert(JSON.stringify(data));
      setUser(data.data);
      // console.log(data.data);
    } catch (err) {
      //   authFailure(err);
      // alert(err);
    }
  };
  useEffect(() => {
    userProfile();
    // console.log(path);
    // alert(path);
  }, []);

  // useEffect(() => {
  //  console.log("Profile");
  //  console.log(notificationSocket);
  //  if (notificationSocket.connected) {
  //    console.log("notificationSocket_______1");

  // notificationSocket.current.on(
  //   "trackBookingStatusForWebBookingApp",
  //    (data) => {
  //     console.log(JSON.stringify(data));
  //   }
  // );
  //  console.log("notificationSocket_______2");
  // }
  //}, [notificationSocket]);

  const profileOptions = [
    {
      icon: <SiLiberapay className="mr-2 mb-1" />,
      title: language.MANAGE_PAYMENT,
      link: NavLinks.CARD_DEATILS + "/profile",
      arrow: <HiChevronRight />,
    },
    {
      icon: <FaWallet className="mr-2 mb-1" />,
      title: language.USER_WALLET,
      link: NavLinks.WALLET,
      arrow: <HiChevronRight />,
    },
    // {
    //   icon: <FcInvite className="mr-2 mb-1" />,
    //   title: "Invite and Earn",
    //   link: "",
    //   arrow: <HiChevronRight />,
    // },
    // {
    //   icon: <SiSololearn className="mr-2 mb-1" />,
    //   title: "Rewards",
    //   link: "",
    //   arrow: <HiChevronRight />,
    // },
  ];

  return (
    <>
      {/* <div
        className="modal show"
        style={{ display: "block", position: "initial" }}
      >
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>Modal title</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Modal body text goes here.</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary">Close</Button>
            <Button variant="primary">Save changes</Button>
          </Modal.Footer>
        </Modal.Dialog>
      </div> */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {language.WARNING_LOGOUT}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h4>Centered Modal</h4> */}
          <p>{language.INFO_CONTINUE_LOGIN}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            {language.CANCEL}
          </Button>
          <Button onClick={adminLogout}>{language.CONFIRM}</Button>
        </Modal.Footer>
      </Modal>
      <main className="zervx_main">
        <div className="zervx_content">
          <section className="header-outer">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-3 col-3">
                  <div className="header-arrow">
                    <Link
                      to={
                        path === "home"
                          ? NavLinks.HOME
                          : path === "accept"
                          ? NavLinks.RIDE_CONFIRMED + "/" + bookingId
                          : path === "arrived"
                          ? NavLinks.ARRIVED + "/" + bookingId
                          : path === "start"
                          ? NavLinks.START_RIDE + "/" + bookingId
                          : path === "review"
                          ? NavLinks.RIDE_REVIEW + "/" + bookingId
                          : path === "searchProfessional"
                          ? NavLinks.SEARCHING_PROFESSIONALS + "/" + bookingId
                          : path === "schedule"
                          ? NavLinks.SCHEDULE_RIDE_BOOKING + "/" + bookingId
                          : NavLinks.HOME
                      }
                    >
                      <svg
                        className="ola-svg ola-icon"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                      >
                        <g>
                          <path d="M7.2 11.5h12.3v1H7.22l4.13 4.15-.7.7-5.36-5.37 5.35-5.33.7.7L7.2 11.5z"></path>
                        </g>
                      </svg>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 col-6">
                  <div className="header-logo text-center">
                    <h5>{language.PROFILE}</h5>
                  </div>
                </div>
                <div className="col-md-3 col-3">
                  {/* <!-- <div className="header-login">
                        <i className="fa fa-user" aria-hidden="true"></i> <a href="#">Login</a>

                     </div> --> */}
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Review_section_start --> */}

          <section className="review_main_otr">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  {user ? (
                    <div className="review_content">
                      <div className=" text-center">
                        <h4>{(user.firstName || language.NO_NAME) + " " + (user.lastName || "")}</h4>
                        <div className="flex justify-center ">
                          <img
                            src={user.avatar}
                            alt=""
                            className="rounded-full h-40 w-40 my-4"
                          />

                          {/* <div className="bg-slate-200 h-40 w-40 rounded-full my-4"></div> */}
                        </div>
                      </div>
                      {/* <Link
                      to={NavLinks.CARD_DEATILS + "/profile"}
                      className=" decoration-transparent"
                    >
                      <div className="md:px-28  ">
                        <div className=" grid grid-cols-2 p-2 my-2 cursor-pointer hover:bg-gray-200 rounded-lg text-black">
                          <div className="flex items-center  w-56">
                            <FaUser className="mr-2 mb-1" />

                            <h6 className="mb-0"> Manage Payments</h6>
                          </div>
                          <div className="flex justify-end items-center ">
                            <HiChevronRight />
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="md:px-28  ">
                      <div className=" grid grid-cols-2 p-2 my-2 cursor-pointer hover:bg-gray-200 rounded-lg text-black">
                        <div className="flex items-center  w-56">
                          <FaUser className="mr-2 mb-1" />
                          <h6 className="mb-0"> User Wallet</h6>
                        </div>
                        <div className="flex justify-end items-center ">
                          <HiChevronRight />
                        </div>
                      </div>
                    </div> */}
                      {profileOptions?.map((each) => (
                        <Link
                          to={each.link}
                          className=" decoration-transparent"
                        >
                          <div className="md:px-28  ">
                            <div className=" grid grid-cols-2 p-2 my-2 cursor-pointer hover:bg-gray-200 rounded-lg text-black">
                              <div className="flex items-center  w-56">
                                {/* <FaUser className="mr-2 mb-1" /> */}
                                {each.icon}
                                <h6 className="mb-0"> {each.title}</h6>
                              </div>
                              <div className="flex justify-end items-center ">
                                <HiChevronRight />
                              </div>
                            </div>
                          </div>
                        </Link>
                      ))}

                      <div
                        className="md:px-28  "
                        onClick={() => {
                          setModalShow(true);
                        }}
                      >
                        <div className=" grid grid-cols-2 p-2 my-2 cursor-pointer hover:bg-gray-200 rounded-lg text-black">
                          <div className="flex items-center  w-56">
                            <FaUser className="mr-2 mb-1" />

                            <h6 className="mb-0">{language.LOG_OUT}</h6>
                          </div>
                          <div className="flex justify-end items-center ">
                            <HiChevronRight />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <SmallLoader />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="zervx_rightimg">
          <img
            src={settings?.bookingAppImage}
            alt=""
            className="w-screen h-screen"
          />

          <div className="global-img-right">
            <h2>{settings?.bookingAppHomePageMessage}</h2>
          </div>
        </div>
      </main>
    </>
  );
}
